@import '../data/covers.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.r-fix-scrollbar {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}
