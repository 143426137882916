.r {
  background: url("covers.2bf8da88.jpg");
}

.r-3938 {
  background-position: 0 0 !important;
}

.r-3747 {
  background-position: -40px 0 !important;
}

.r-3745 {
  background-position: -80px 0 !important;
}

.r-3744 {
  background-position: -120px 0 !important;
}

.r-3743 {
  background-position: -160px 0 !important;
}

.r-3741 {
  background-position: -200px 0 !important;
}

.r-3740 {
  background-position: -240px 0 !important;
}

.r-3739 {
  background-position: -280px 0 !important;
}

.r-3738 {
  background-position: -320px 0 !important;
}

.r-3737 {
  background-position: -360px 0 !important;
}

.r-3735 {
  background-position: -400px 0 !important;
}

.r-3734 {
  background-position: -440px 0 !important;
}

.r-3733 {
  background-position: -480px 0 !important;
}

.r-3732 {
  background-position: -520px 0 !important;
}

.r-3731 {
  background-position: -560px 0 !important;
}

.r-3729 {
  background-position: -600px 0 !important;
}

.r-3726 {
  background-position: -640px 0 !important;
}

.r-3725 {
  background-position: -680px 0 !important;
}

.r-3723 {
  background-position: -720px 0 !important;
}

.r-3722 {
  background-position: -760px 0 !important;
}

.r-3721 {
  background-position: -800px 0 !important;
}

.r-3719 {
  background-position: -840px 0 !important;
}

.r-3718 {
  background-position: -880px 0 !important;
}

.r-3717 {
  background-position: -920px 0 !important;
}

.r-3716 {
  background-position: -960px 0 !important;
}

.r-3715 {
  background-position: 0 -40px !important;
}

.r-3714 {
  background-position: -40px -40px !important;
}

.r-3713 {
  background-position: -80px -40px !important;
}

.r-3712 {
  background-position: -120px -40px !important;
}

.r-3711 {
  background-position: -160px -40px !important;
}

.r-3710 {
  background-position: -200px -40px !important;
}

.r-3709 {
  background-position: -240px -40px !important;
}

.r-3708 {
  background-position: -280px -40px !important;
}

.r-3707 {
  background-position: -320px -40px !important;
}

.r-3706 {
  background-position: -360px -40px !important;
}

.r-3705 {
  background-position: -400px -40px !important;
}

.r-3704 {
  background-position: -440px -40px !important;
}

.r-3703 {
  background-position: -480px -40px !important;
}

.r-3702 {
  background-position: -520px -40px !important;
}

.r-3701 {
  background-position: -560px -40px !important;
}

.r-3700 {
  background-position: -600px -40px !important;
}

.r-3699 {
  background-position: -640px -40px !important;
}

.r-3698 {
  background-position: -680px -40px !important;
}

.r-3697 {
  background-position: -720px -40px !important;
}

.r-3696 {
  background-position: -760px -40px !important;
}

.r-3695 {
  background-position: -800px -40px !important;
}

.r-3694 {
  background-position: -840px -40px !important;
}

.r-3693 {
  background-position: -880px -40px !important;
}

.r-3692 {
  background-position: -920px -40px !important;
}

.r-3690 {
  background-position: -960px -40px !important;
}

.r-3689 {
  background-position: 0 -80px !important;
}

.r-3688 {
  background-position: -40px -80px !important;
}

.r-3687 {
  background-position: -80px -80px !important;
}

.r-3686 {
  background-position: -120px -80px !important;
}

.r-3685 {
  background-position: -160px -80px !important;
}

.r-3684 {
  background-position: -200px -80px !important;
}

.r-3683 {
  background-position: -240px -80px !important;
}

.r-3682 {
  background-position: -280px -80px !important;
}

.r-3681 {
  background-position: -320px -80px !important;
}

.r-3680 {
  background-position: -360px -80px !important;
}

.r-3679 {
  background-position: -400px -80px !important;
}

.r-3678 {
  background-position: -440px -80px !important;
}

.r-3677 {
  background-position: -480px -80px !important;
}

.r-3676 {
  background-position: -520px -80px !important;
}

.r-3675 {
  background-position: -560px -80px !important;
}

.r-3674 {
  background-position: -600px -80px !important;
}

.r-3673 {
  background-position: -640px -80px !important;
}

.r-3672 {
  background-position: -680px -80px !important;
}

.r-3671 {
  background-position: -720px -80px !important;
}

.r-3670 {
  background-position: -760px -80px !important;
}

.r-3669 {
  background-position: -800px -80px !important;
}

.r-3668 {
  background-position: -840px -80px !important;
}

.r-3667 {
  background-position: -880px -80px !important;
}

.r-3666 {
  background-position: -920px -80px !important;
}

.r-3665 {
  background-position: -960px -80px !important;
}

.r-3664 {
  background-position: 0 -120px !important;
}

.r-3663 {
  background-position: -40px -120px !important;
}

.r-3662 {
  background-position: -80px -120px !important;
}

.r-3661 {
  background-position: -120px -120px !important;
}

.r-3660 {
  background-position: -160px -120px !important;
}

.r-3659 {
  background-position: -200px -120px !important;
}

.r-3658 {
  background-position: -240px -120px !important;
}

.r-3657 {
  background-position: -280px -120px !important;
}

.r-3656 {
  background-position: -320px -120px !important;
}

.r-3655 {
  background-position: -360px -120px !important;
}

.r-3654 {
  background-position: -400px -120px !important;
}

.r-3653 {
  background-position: -440px -120px !important;
}

.r-3652 {
  background-position: -480px -120px !important;
}

.r-3651 {
  background-position: -520px -120px !important;
}

.r-3650 {
  background-position: -560px -120px !important;
}

.r-3649 {
  background-position: -600px -120px !important;
}

.r-3648 {
  background-position: -640px -120px !important;
}

.r-3647 {
  background-position: -680px -120px !important;
}

.r-3646 {
  background-position: -720px -120px !important;
}

.r-3645 {
  background-position: -760px -120px !important;
}

.r-3644 {
  background-position: -800px -120px !important;
}

.r-3643 {
  background-position: -840px -120px !important;
}

.r-3642 {
  background-position: -880px -120px !important;
}

.r-3641 {
  background-position: -920px -120px !important;
}

.r-3640 {
  background-position: -960px -120px !important;
}

.r-3639 {
  background-position: 0 -160px !important;
}

.r-3638 {
  background-position: -40px -160px !important;
}

.r-3637 {
  background-position: -80px -160px !important;
}

.r-3636 {
  background-position: -120px -160px !important;
}

.r-3635 {
  background-position: -160px -160px !important;
}

.r-3634 {
  background-position: -200px -160px !important;
}

.r-3633 {
  background-position: -240px -160px !important;
}

.r-3632 {
  background-position: -280px -160px !important;
}

.r-3631 {
  background-position: -320px -160px !important;
}

.r-3630 {
  background-position: -360px -160px !important;
}

.r-3629 {
  background-position: -400px -160px !important;
}

.r-3628 {
  background-position: -440px -160px !important;
}

.r-3627 {
  background-position: -480px -160px !important;
}

.r-3626 {
  background-position: -520px -160px !important;
}

.r-3625 {
  background-position: -560px -160px !important;
}

.r-3624 {
  background-position: -600px -160px !important;
}

.r-3623 {
  background-position: -640px -160px !important;
}

.r-3622 {
  background-position: -680px -160px !important;
}

.r-3621 {
  background-position: -720px -160px !important;
}

.r-3620 {
  background-position: -760px -160px !important;
}

.r-3619 {
  background-position: -800px -160px !important;
}

.r-3618 {
  background-position: -840px -160px !important;
}

.r-3617 {
  background-position: -880px -160px !important;
}

.r-3616 {
  background-position: -920px -160px !important;
}

.r-3615 {
  background-position: -960px -160px !important;
}

.r-3614 {
  background-position: 0 -200px !important;
}

.r-3613 {
  background-position: -40px -200px !important;
}

.r-3612 {
  background-position: -80px -200px !important;
}

.r-3611 {
  background-position: -120px -200px !important;
}

.r-3610 {
  background-position: -160px -200px !important;
}

.r-3609 {
  background-position: -200px -200px !important;
}

.r-3608 {
  background-position: -240px -200px !important;
}

.r-3607 {
  background-position: -280px -200px !important;
}

.r-3606 {
  background-position: -320px -200px !important;
}

.r-3605 {
  background-position: -360px -200px !important;
}

.r-3604 {
  background-position: -400px -200px !important;
}

.r-3603 {
  background-position: -440px -200px !important;
}

.r-3602 {
  background-position: -480px -200px !important;
}

.r-3601 {
  background-position: -520px -200px !important;
}

.r-3600 {
  background-position: -560px -200px !important;
}

.r-3599 {
  background-position: -600px -200px !important;
}

.r-3598 {
  background-position: -640px -200px !important;
}

.r-3597 {
  background-position: -680px -200px !important;
}

.r-3596 {
  background-position: -720px -200px !important;
}

.r-3595 {
  background-position: -760px -200px !important;
}

.r-3594 {
  background-position: -800px -200px !important;
}

.r-3593 {
  background-position: -840px -200px !important;
}

.r-3592 {
  background-position: -880px -200px !important;
}

.r-3591 {
  background-position: -920px -200px !important;
}

.r-3590 {
  background-position: -960px -200px !important;
}

.r-3589 {
  background-position: 0 -240px !important;
}

.r-3588 {
  background-position: -40px -240px !important;
}

.r-3587 {
  background-position: -80px -240px !important;
}

.r-3586 {
  background-position: -120px -240px !important;
}

.r-3585 {
  background-position: -160px -240px !important;
}

.r-3584 {
  background-position: -200px -240px !important;
}

.r-3582 {
  background-position: -240px -240px !important;
}

.r-3581 {
  background-position: -280px -240px !important;
}

.r-3580 {
  background-position: -320px -240px !important;
}

.r-3579 {
  background-position: -360px -240px !important;
}

.r-3578 {
  background-position: -400px -240px !important;
}

.r-3577 {
  background-position: -440px -240px !important;
}

.r-3576 {
  background-position: -480px -240px !important;
}

.r-3575 {
  background-position: -520px -240px !important;
}

.r-3574 {
  background-position: -560px -240px !important;
}

.r-3573 {
  background-position: -600px -240px !important;
}

.r-3572 {
  background-position: -640px -240px !important;
}

.r-3571 {
  background-position: -680px -240px !important;
}

.r-3570 {
  background-position: -720px -240px !important;
}

.r-3569 {
  background-position: -760px -240px !important;
}

.r-3568 {
  background-position: -800px -240px !important;
}

.r-3567 {
  background-position: -840px -240px !important;
}

.r-3566 {
  background-position: -880px -240px !important;
}

.r-3565 {
  background-position: -920px -240px !important;
}

.r-3564 {
  background-position: -960px -240px !important;
}

.r-3563 {
  background-position: 0 -280px !important;
}

.r-3562 {
  background-position: -40px -280px !important;
}

.r-3561 {
  background-position: -80px -280px !important;
}

.r-3560 {
  background-position: -120px -280px !important;
}

.r-3559 {
  background-position: -160px -280px !important;
}

.r-3558 {
  background-position: -200px -280px !important;
}

.r-3557 {
  background-position: -240px -280px !important;
}

.r-3556 {
  background-position: -280px -280px !important;
}

.r-3555 {
  background-position: -320px -280px !important;
}

.r-3554 {
  background-position: -360px -280px !important;
}

.r-3553 {
  background-position: -400px -280px !important;
}

.r-3552 {
  background-position: -440px -280px !important;
}

.r-3551 {
  background-position: -480px -280px !important;
}

.r-3550 {
  background-position: -520px -280px !important;
}

.r-3549 {
  background-position: -560px -280px !important;
}

.r-3548 {
  background-position: -600px -280px !important;
}

.r-3547 {
  background-position: -640px -280px !important;
}

.r-3546 {
  background-position: -680px -280px !important;
}

.r-3545 {
  background-position: -720px -280px !important;
}

.r-3544 {
  background-position: -760px -280px !important;
}

.r-3543 {
  background-position: -800px -280px !important;
}

.r-3542 {
  background-position: -840px -280px !important;
}

.r-3541 {
  background-position: -880px -280px !important;
}

.r-3540 {
  background-position: -920px -280px !important;
}

.r-3539 {
  background-position: -960px -280px !important;
}

.r-3538 {
  background-position: 0 -320px !important;
}

.r-3537 {
  background-position: -40px -320px !important;
}

.r-3536 {
  background-position: -80px -320px !important;
}

.r-3535 {
  background-position: -120px -320px !important;
}

.r-3534 {
  background-position: -160px -320px !important;
}

.r-3533 {
  background-position: -200px -320px !important;
}

.r-3532 {
  background-position: -240px -320px !important;
}

.r-3531 {
  background-position: -280px -320px !important;
}

.r-3530 {
  background-position: -320px -320px !important;
}

.r-3529 {
  background-position: -360px -320px !important;
}

.r-3528 {
  background-position: -400px -320px !important;
}

.r-3527 {
  background-position: -440px -320px !important;
}

.r-3526 {
  background-position: -480px -320px !important;
}

.r-3525 {
  background-position: -520px -320px !important;
}

.r-3524 {
  background-position: -560px -320px !important;
}

.r-3523 {
  background-position: -600px -320px !important;
}

.r-3522 {
  background-position: -640px -320px !important;
}

.r-3521 {
  background-position: -680px -320px !important;
}

.r-3520 {
  background-position: -720px -320px !important;
}

.r-3519 {
  background-position: -760px -320px !important;
}

.r-3518 {
  background-position: -800px -320px !important;
}

.r-3517 {
  background-position: -840px -320px !important;
}

.r-3516 {
  background-position: -880px -320px !important;
}

.r-3515 {
  background-position: -920px -320px !important;
}

.r-3514 {
  background-position: -960px -320px !important;
}

.r-3513 {
  background-position: 0 -360px !important;
}

.r-3512 {
  background-position: -40px -360px !important;
}

.r-3511 {
  background-position: -80px -360px !important;
}

.r-3510 {
  background-position: -120px -360px !important;
}

.r-3509 {
  background-position: -160px -360px !important;
}

.r-3508 {
  background-position: -200px -360px !important;
}

.r-3507 {
  background-position: -240px -360px !important;
}

.r-3506 {
  background-position: -280px -360px !important;
}

.r-3505 {
  background-position: -320px -360px !important;
}

.r-3504 {
  background-position: -360px -360px !important;
}

.r-3503 {
  background-position: -400px -360px !important;
}

.r-3502 {
  background-position: -440px -360px !important;
}

.r-3501 {
  background-position: -480px -360px !important;
}

.r-3500 {
  background-position: -520px -360px !important;
}

.r-3499 {
  background-position: -560px -360px !important;
}

.r-3498 {
  background-position: -600px -360px !important;
}

.r-3497 {
  background-position: -640px -360px !important;
}

.r-3496 {
  background-position: -680px -360px !important;
}

.r-3495 {
  background-position: -720px -360px !important;
}

.r-3494 {
  background-position: -760px -360px !important;
}

.r-3493 {
  background-position: -800px -360px !important;
}

.r-3492 {
  background-position: -840px -360px !important;
}

.r-3491 {
  background-position: -880px -360px !important;
}

.r-3490 {
  background-position: -920px -360px !important;
}

.r-3489 {
  background-position: -960px -360px !important;
}

.r-3488 {
  background-position: 0 -400px !important;
}

.r-3487 {
  background-position: -40px -400px !important;
}

.r-3486 {
  background-position: -80px -400px !important;
}

.r-3483 {
  background-position: -120px -400px !important;
}

.r-3482 {
  background-position: -160px -400px !important;
}

.r-3481 {
  background-position: -200px -400px !important;
}

.r-3480 {
  background-position: -240px -400px !important;
}

.r-3479 {
  background-position: -280px -400px !important;
}

.r-3478 {
  background-position: -320px -400px !important;
}

.r-3477 {
  background-position: -360px -400px !important;
}

.r-3476 {
  background-position: -400px -400px !important;
}

.r-3475 {
  background-position: -440px -400px !important;
}

.r-3474 {
  background-position: -480px -400px !important;
}

.r-3473 {
  background-position: -520px -400px !important;
}

.r-3472 {
  background-position: -560px -400px !important;
}

.r-3471 {
  background-position: -600px -400px !important;
}

.r-3470 {
  background-position: -640px -400px !important;
}

.r-3469 {
  background-position: -680px -400px !important;
}

.r-3468 {
  background-position: -720px -400px !important;
}

.r-3467 {
  background-position: -760px -400px !important;
}

.r-3466 {
  background-position: -800px -400px !important;
}

.r-3465 {
  background-position: -840px -400px !important;
}

.r-3464 {
  background-position: -880px -400px !important;
}

.r-3463 {
  background-position: -920px -400px !important;
}

.r-3462 {
  background-position: -960px -400px !important;
}

.r-3461 {
  background-position: 0 -440px !important;
}

.r-3460 {
  background-position: -40px -440px !important;
}

.r-3459 {
  background-position: -80px -440px !important;
}

.r-3458 {
  background-position: -120px -440px !important;
}

.r-3457 {
  background-position: -160px -440px !important;
}

.r-3456 {
  background-position: -200px -440px !important;
}

.r-3455 {
  background-position: -240px -440px !important;
}

.r-3454 {
  background-position: -280px -440px !important;
}

.r-3453 {
  background-position: -320px -440px !important;
}

.r-3452 {
  background-position: -360px -440px !important;
}

.r-3451 {
  background-position: -400px -440px !important;
}

.r-3450 {
  background-position: -440px -440px !important;
}

.r-3449 {
  background-position: -480px -440px !important;
}

.r-3448 {
  background-position: -520px -440px !important;
}

.r-3447 {
  background-position: -560px -440px !important;
}

.r-3446 {
  background-position: -600px -440px !important;
}

.r-3445 {
  background-position: -640px -440px !important;
}

.r-3444 {
  background-position: -680px -440px !important;
}

.r-3443 {
  background-position: -720px -440px !important;
}

.r-3442 {
  background-position: -760px -440px !important;
}

.r-3441 {
  background-position: -800px -440px !important;
}

.r-3440 {
  background-position: -840px -440px !important;
}

.r-3439 {
  background-position: -880px -440px !important;
}

.r-3438 {
  background-position: -920px -440px !important;
}

.r-3437 {
  background-position: -960px -440px !important;
}

.r-3436 {
  background-position: 0 -480px !important;
}

.r-3435 {
  background-position: -40px -480px !important;
}

.r-3434 {
  background-position: -80px -480px !important;
}

.r-3433 {
  background-position: -120px -480px !important;
}

.r-3432 {
  background-position: -160px -480px !important;
}

.r-3431 {
  background-position: -200px -480px !important;
}

.r-3430 {
  background-position: -240px -480px !important;
}

.r-3429 {
  background-position: -280px -480px !important;
}

.r-3428 {
  background-position: -320px -480px !important;
}

.r-3427 {
  background-position: -360px -480px !important;
}

.r-3426 {
  background-position: -400px -480px !important;
}

.r-3425 {
  background-position: -440px -480px !important;
}

.r-3424 {
  background-position: -480px -480px !important;
}

.r-3423 {
  background-position: -520px -480px !important;
}

.r-3422 {
  background-position: -560px -480px !important;
}

.r-3421 {
  background-position: -600px -480px !important;
}

.r-3420 {
  background-position: -640px -480px !important;
}

.r-3419 {
  background-position: -680px -480px !important;
}

.r-3418 {
  background-position: -720px -480px !important;
}

.r-3417 {
  background-position: -760px -480px !important;
}

.r-3416 {
  background-position: -800px -480px !important;
}

.r-3415 {
  background-position: -840px -480px !important;
}

.r-3414 {
  background-position: -880px -480px !important;
}

.r-3413 {
  background-position: -920px -480px !important;
}

.r-3412 {
  background-position: -960px -480px !important;
}

.r-3411 {
  background-position: 0 -520px !important;
}

.r-3410 {
  background-position: -40px -520px !important;
}

.r-3409 {
  background-position: -80px -520px !important;
}

.r-3408 {
  background-position: -120px -520px !important;
}

.r-3407 {
  background-position: -160px -520px !important;
}

.r-3406 {
  background-position: -200px -520px !important;
}

.r-3405 {
  background-position: -240px -520px !important;
}

.r-3404 {
  background-position: -280px -520px !important;
}

.r-3403 {
  background-position: -320px -520px !important;
}

.r-3402 {
  background-position: -360px -520px !important;
}

.r-3401 {
  background-position: -400px -520px !important;
}

.r-3400 {
  background-position: -440px -520px !important;
}

.r-3399 {
  background-position: -480px -520px !important;
}

.r-3398 {
  background-position: -520px -520px !important;
}

.r-3397 {
  background-position: -560px -520px !important;
}

.r-3396 {
  background-position: -600px -520px !important;
}

.r-3395 {
  background-position: -640px -520px !important;
}

.r-3394 {
  background-position: -680px -520px !important;
}

.r-3393 {
  background-position: -720px -520px !important;
}

.r-3392 {
  background-position: -760px -520px !important;
}

.r-3391 {
  background-position: -800px -520px !important;
}

.r-3390 {
  background-position: -840px -520px !important;
}

.r-3389 {
  background-position: -880px -520px !important;
}

.r-3388 {
  background-position: -920px -520px !important;
}

.r-3387 {
  background-position: -960px -520px !important;
}

.r-3386 {
  background-position: 0 -560px !important;
}

.r-3385 {
  background-position: -40px -560px !important;
}

.r-3384 {
  background-position: -80px -560px !important;
}

.r-3383 {
  background-position: -120px -560px !important;
}

.r-3382 {
  background-position: -160px -560px !important;
}

.r-3381 {
  background-position: -200px -560px !important;
}

.r-3380 {
  background-position: -240px -560px !important;
}

.r-3379 {
  background-position: -280px -560px !important;
}

.r-3378 {
  background-position: -320px -560px !important;
}

.r-3376 {
  background-position: -360px -560px !important;
}

.r-3375 {
  background-position: -400px -560px !important;
}

.r-3374 {
  background-position: -440px -560px !important;
}

.r-3373 {
  background-position: -480px -560px !important;
}

.r-3372 {
  background-position: -520px -560px !important;
}

.r-3371 {
  background-position: -560px -560px !important;
}

.r-3370 {
  background-position: -600px -560px !important;
}

.r-3369 {
  background-position: -640px -560px !important;
}

.r-3368 {
  background-position: -680px -560px !important;
}

.r-3367 {
  background-position: -720px -560px !important;
}

.r-3366 {
  background-position: -760px -560px !important;
}

.r-3365 {
  background-position: -800px -560px !important;
}

.r-3364 {
  background-position: -840px -560px !important;
}

.r-3363 {
  background-position: -880px -560px !important;
}

.r-3362 {
  background-position: -920px -560px !important;
}

.r-3361 {
  background-position: -960px -560px !important;
}

.r-3360 {
  background-position: 0 -600px !important;
}

.r-3359 {
  background-position: -40px -600px !important;
}

.r-3358 {
  background-position: -80px -600px !important;
}

.r-3357 {
  background-position: -120px -600px !important;
}

.r-3356 {
  background-position: -160px -600px !important;
}

.r-3355 {
  background-position: -200px -600px !important;
}

.r-3354 {
  background-position: -240px -600px !important;
}

.r-3353 {
  background-position: -280px -600px !important;
}

.r-3352 {
  background-position: -320px -600px !important;
}

.r-3351 {
  background-position: -360px -600px !important;
}

.r-3350 {
  background-position: -400px -600px !important;
}

.r-3349 {
  background-position: -440px -600px !important;
}

.r-3348 {
  background-position: -480px -600px !important;
}

.r-3347 {
  background-position: -520px -600px !important;
}

.r-3346 {
  background-position: -560px -600px !important;
}

.r-3345 {
  background-position: -600px -600px !important;
}

.r-3344 {
  background-position: -640px -600px !important;
}

.r-3343 {
  background-position: -680px -600px !important;
}

.r-3342 {
  background-position: -720px -600px !important;
}

.r-3341 {
  background-position: -760px -600px !important;
}

.r-3340 {
  background-position: -800px -600px !important;
}

.r-3339 {
  background-position: -840px -600px !important;
}

.r-3338 {
  background-position: -880px -600px !important;
}

.r-3337 {
  background-position: -920px -600px !important;
}

.r-3336 {
  background-position: -960px -600px !important;
}

.r-3335 {
  background-position: 0 -640px !important;
}

.r-3334 {
  background-position: -40px -640px !important;
}

.r-3333 {
  background-position: -80px -640px !important;
}

.r-3332 {
  background-position: -120px -640px !important;
}

.r-3331 {
  background-position: -160px -640px !important;
}

.r-3330 {
  background-position: -200px -640px !important;
}

.r-3329 {
  background-position: -240px -640px !important;
}

.r-3328 {
  background-position: -280px -640px !important;
}

.r-3327 {
  background-position: -320px -640px !important;
}

.r-3326 {
  background-position: -360px -640px !important;
}

.r-3325 {
  background-position: -400px -640px !important;
}

.r-3324 {
  background-position: -440px -640px !important;
}

.r-3323 {
  background-position: -480px -640px !important;
}

.r-3322 {
  background-position: -520px -640px !important;
}

.r-3321 {
  background-position: -560px -640px !important;
}

.r-3320 {
  background-position: -600px -640px !important;
}

.r-3319 {
  background-position: -640px -640px !important;
}

.r-3318 {
  background-position: -680px -640px !important;
}

.r-3317 {
  background-position: -720px -640px !important;
}

.r-3316 {
  background-position: -760px -640px !important;
}

.r-3315 {
  background-position: -800px -640px !important;
}

.r-3314 {
  background-position: -840px -640px !important;
}

.r-3313 {
  background-position: -880px -640px !important;
}

.r-3312 {
  background-position: -920px -640px !important;
}

.r-3311 {
  background-position: -960px -640px !important;
}

.r-3310 {
  background-position: 0 -680px !important;
}

.r-3309 {
  background-position: -40px -680px !important;
}

.r-3308 {
  background-position: -80px -680px !important;
}

.r-3307 {
  background-position: -120px -680px !important;
}

.r-3306 {
  background-position: -160px -680px !important;
}

.r-3305 {
  background-position: -200px -680px !important;
}

.r-3304 {
  background-position: -240px -680px !important;
}

.r-3303 {
  background-position: -280px -680px !important;
}

.r-3302 {
  background-position: -320px -680px !important;
}

.r-3301 {
  background-position: -360px -680px !important;
}

.r-3300 {
  background-position: -400px -680px !important;
}

.r-3299 {
  background-position: -440px -680px !important;
}

.r-3298 {
  background-position: -480px -680px !important;
}

.r-3297 {
  background-position: -520px -680px !important;
}

.r-3296 {
  background-position: -560px -680px !important;
}

.r-3295 {
  background-position: -600px -680px !important;
}

.r-3294 {
  background-position: -640px -680px !important;
}

.r-3293 {
  background-position: -680px -680px !important;
}

.r-3292 {
  background-position: -720px -680px !important;
}

.r-3291 {
  background-position: -760px -680px !important;
}

.r-3290 {
  background-position: -800px -680px !important;
}

.r-3289 {
  background-position: -840px -680px !important;
}

.r-3288 {
  background-position: -880px -680px !important;
}

.r-3287 {
  background-position: -920px -680px !important;
}

.r-3286 {
  background-position: -960px -680px !important;
}

.r-3285 {
  background-position: 0 -720px !important;
}

.r-3284 {
  background-position: -40px -720px !important;
}

.r-3283 {
  background-position: -80px -720px !important;
}

.r-3282 {
  background-position: -120px -720px !important;
}

.r-3281 {
  background-position: -160px -720px !important;
}

.r-3280 {
  background-position: -200px -720px !important;
}

.r-3279 {
  background-position: -240px -720px !important;
}

.r-3278 {
  background-position: -280px -720px !important;
}

.r-3277 {
  background-position: -320px -720px !important;
}

.r-3276 {
  background-position: -360px -720px !important;
}

.r-3275 {
  background-position: -400px -720px !important;
}

.r-3274 {
  background-position: -440px -720px !important;
}

.r-3273 {
  background-position: -480px -720px !important;
}

.r-3272 {
  background-position: -520px -720px !important;
}

.r-3271 {
  background-position: -560px -720px !important;
}

.r-3270 {
  background-position: -600px -720px !important;
}

.r-3269 {
  background-position: -640px -720px !important;
}

.r-3268 {
  background-position: -680px -720px !important;
}

.r-3267 {
  background-position: -720px -720px !important;
}

.r-3266 {
  background-position: -760px -720px !important;
}

.r-3265 {
  background-position: -800px -720px !important;
}

.r-3264 {
  background-position: -840px -720px !important;
}

.r-3263 {
  background-position: -880px -720px !important;
}

.r-3262 {
  background-position: -920px -720px !important;
}

.r-3261 {
  background-position: -960px -720px !important;
}

.r-3260 {
  background-position: 0 -760px !important;
}

.r-3259 {
  background-position: -40px -760px !important;
}

.r-3258 {
  background-position: -80px -760px !important;
}

.r-3257 {
  background-position: -120px -760px !important;
}

.r-3256 {
  background-position: -160px -760px !important;
}

.r-3255 {
  background-position: -200px -760px !important;
}

.r-3254 {
  background-position: -240px -760px !important;
}

.r-3253 {
  background-position: -280px -760px !important;
}

.r-3252 {
  background-position: -320px -760px !important;
}

.r-3251 {
  background-position: -360px -760px !important;
}

.r-3250 {
  background-position: -400px -760px !important;
}

.r-3249 {
  background-position: -440px -760px !important;
}

.r-3248 {
  background-position: -480px -760px !important;
}

.r-3247 {
  background-position: -520px -760px !important;
}

.r-3246 {
  background-position: -560px -760px !important;
}

.r-3245 {
  background-position: -600px -760px !important;
}

.r-3244 {
  background-position: -640px -760px !important;
}

.r-3243 {
  background-position: -680px -760px !important;
}

.r-3242 {
  background-position: -720px -760px !important;
}

.r-3241 {
  background-position: -760px -760px !important;
}

.r-3240 {
  background-position: -800px -760px !important;
}

.r-3239 {
  background-position: -840px -760px !important;
}

.r-3238 {
  background-position: -880px -760px !important;
}

.r-3237 {
  background-position: -920px -760px !important;
}

.r-3236 {
  background-position: -960px -760px !important;
}

.r-3235 {
  background-position: 0 -800px !important;
}

.r-3234 {
  background-position: -40px -800px !important;
}

.r-3233 {
  background-position: -80px -800px !important;
}

.r-3232 {
  background-position: -120px -800px !important;
}

.r-3231 {
  background-position: -160px -800px !important;
}

.r-3230 {
  background-position: -200px -800px !important;
}

.r-3229 {
  background-position: -240px -800px !important;
}

.r-3228 {
  background-position: -280px -800px !important;
}

.r-3227 {
  background-position: -320px -800px !important;
}

.r-3226 {
  background-position: -360px -800px !important;
}

.r-3225 {
  background-position: -400px -800px !important;
}

.r-3224 {
  background-position: -440px -800px !important;
}

.r-3223 {
  background-position: -480px -800px !important;
}

.r-3222 {
  background-position: -520px -800px !important;
}

.r-3221 {
  background-position: -560px -800px !important;
}

.r-3220 {
  background-position: -600px -800px !important;
}

.r-3219 {
  background-position: -640px -800px !important;
}

.r-3218 {
  background-position: -680px -800px !important;
}

.r-3217 {
  background-position: -720px -800px !important;
}

.r-3216 {
  background-position: -760px -800px !important;
}

.r-3215 {
  background-position: -800px -800px !important;
}

.r-3214 {
  background-position: -840px -800px !important;
}

.r-3213 {
  background-position: -880px -800px !important;
}

.r-3212 {
  background-position: -920px -800px !important;
}

.r-3211 {
  background-position: -960px -800px !important;
}

.r-3210 {
  background-position: 0 -840px !important;
}

.r-3209 {
  background-position: -40px -840px !important;
}

.r-3208 {
  background-position: -80px -840px !important;
}

.r-3207 {
  background-position: -120px -840px !important;
}

.r-3206 {
  background-position: -160px -840px !important;
}

.r-3205 {
  background-position: -200px -840px !important;
}

.r-3204 {
  background-position: -240px -840px !important;
}

.r-3203 {
  background-position: -280px -840px !important;
}

.r-3202 {
  background-position: -320px -840px !important;
}

.r-3201 {
  background-position: -360px -840px !important;
}

.r-3200 {
  background-position: -400px -840px !important;
}

.r-3199 {
  background-position: -440px -840px !important;
}

.r-3198 {
  background-position: -480px -840px !important;
}

.r-3197 {
  background-position: -520px -840px !important;
}

.r-3196 {
  background-position: -560px -840px !important;
}

.r-3195 {
  background-position: -600px -840px !important;
}

.r-3194 {
  background-position: -640px -840px !important;
}

.r-3193 {
  background-position: -680px -840px !important;
}

.r-3192 {
  background-position: -720px -840px !important;
}

.r-3191 {
  background-position: -760px -840px !important;
}

.r-3190 {
  background-position: -800px -840px !important;
}

.r-3189 {
  background-position: -840px -840px !important;
}

.r-3188 {
  background-position: -880px -840px !important;
}

.r-3187 {
  background-position: -920px -840px !important;
}

.r-3186 {
  background-position: -960px -840px !important;
}

.r-3185 {
  background-position: 0 -880px !important;
}

.r-3184 {
  background-position: -40px -880px !important;
}

.r-3183 {
  background-position: -80px -880px !important;
}

.r-3182 {
  background-position: -120px -880px !important;
}

.r-3181 {
  background-position: -160px -880px !important;
}

.r-3180 {
  background-position: -200px -880px !important;
}

.r-3179 {
  background-position: -240px -880px !important;
}

.r-3178 {
  background-position: -280px -880px !important;
}

.r-3177 {
  background-position: -320px -880px !important;
}

.r-3176 {
  background-position: -360px -880px !important;
}

.r-3175 {
  background-position: -400px -880px !important;
}

.r-3173 {
  background-position: -440px -880px !important;
}

.r-3172 {
  background-position: -480px -880px !important;
}

.r-3171 {
  background-position: -520px -880px !important;
}

.r-3170 {
  background-position: -560px -880px !important;
}

.r-3169 {
  background-position: -600px -880px !important;
}

.r-3168 {
  background-position: -640px -880px !important;
}

.r-3167 {
  background-position: -680px -880px !important;
}

.r-3166 {
  background-position: -720px -880px !important;
}

.r-3165 {
  background-position: -760px -880px !important;
}

.r-3164 {
  background-position: -800px -880px !important;
}

.r-3163 {
  background-position: -840px -880px !important;
}

.r-3162 {
  background-position: -880px -880px !important;
}

.r-3161 {
  background-position: -920px -880px !important;
}

.r-3160 {
  background-position: -960px -880px !important;
}

.r-3159 {
  background-position: 0 -920px !important;
}

.r-3158 {
  background-position: -40px -920px !important;
}

.r-3157 {
  background-position: -80px -920px !important;
}

.r-3156 {
  background-position: -120px -920px !important;
}

.r-3155 {
  background-position: -160px -920px !important;
}

.r-3154 {
  background-position: -200px -920px !important;
}

.r-3153 {
  background-position: -240px -920px !important;
}

.r-3152 {
  background-position: -280px -920px !important;
}

.r-3151 {
  background-position: -320px -920px !important;
}

.r-3150 {
  background-position: -360px -920px !important;
}

.r-3149 {
  background-position: -400px -920px !important;
}

.r-3148 {
  background-position: -440px -920px !important;
}

.r-3147 {
  background-position: -480px -920px !important;
}

.r-3146 {
  background-position: -520px -920px !important;
}

.r-3145 {
  background-position: -560px -920px !important;
}

.r-3144 {
  background-position: -600px -920px !important;
}

.r-3143 {
  background-position: -640px -920px !important;
}

.r-3142 {
  background-position: -680px -920px !important;
}

.r-3141 {
  background-position: -720px -920px !important;
}

.r-3140 {
  background-position: -760px -920px !important;
}

.r-3139 {
  background-position: -800px -920px !important;
}

.r-3138 {
  background-position: -840px -920px !important;
}

.r-3137 {
  background-position: -880px -920px !important;
}

.r-3136 {
  background-position: -920px -920px !important;
}

.r-3135 {
  background-position: -960px -920px !important;
}

.r-3134 {
  background-position: 0 -960px !important;
}

.r-3133 {
  background-position: -40px -960px !important;
}

.r-3132 {
  background-position: -80px -960px !important;
}

.r-3131 {
  background-position: -120px -960px !important;
}

.r-3130 {
  background-position: -160px -960px !important;
}

.r-3129 {
  background-position: -200px -960px !important;
}

.r-3128 {
  background-position: -240px -960px !important;
}

.r-3127 {
  background-position: -280px -960px !important;
}

.r-3126 {
  background-position: -320px -960px !important;
}

.r-3125 {
  background-position: -360px -960px !important;
}

.r-3124 {
  background-position: -400px -960px !important;
}

.r-3123 {
  background-position: -440px -960px !important;
}

.r-3122 {
  background-position: -480px -960px !important;
}

.r-3121 {
  background-position: -520px -960px !important;
}

.r-3120 {
  background-position: -560px -960px !important;
}

.r-3119 {
  background-position: -600px -960px !important;
}

.r-3118 {
  background-position: -640px -960px !important;
}

.r-3117 {
  background-position: -680px -960px !important;
}

.r-3116 {
  background-position: -720px -960px !important;
}

.r-3115 {
  background-position: -760px -960px !important;
}

.r-3114 {
  background-position: -800px -960px !important;
}

.r-3113 {
  background-position: -840px -960px !important;
}

.r-3112 {
  background-position: -880px -960px !important;
}

.r-3111 {
  background-position: -920px -960px !important;
}

.r-3110 {
  background-position: -960px -960px !important;
}

.r-3109 {
  background-position: 0 -1000px !important;
}

.r-3108 {
  background-position: -40px -1000px !important;
}

.r-3107 {
  background-position: -80px -1000px !important;
}

.r-3106 {
  background-position: -120px -1000px !important;
}

.r-3105 {
  background-position: -160px -1000px !important;
}

.r-3104 {
  background-position: -200px -1000px !important;
}

.r-3103 {
  background-position: -240px -1000px !important;
}

.r-3102 {
  background-position: -280px -1000px !important;
}

.r-3101 {
  background-position: -320px -1000px !important;
}

.r-3100 {
  background-position: -360px -1000px !important;
}

.r-3099 {
  background-position: -400px -1000px !important;
}

.r-3098 {
  background-position: -440px -1000px !important;
}

.r-3097 {
  background-position: -480px -1000px !important;
}

.r-3096 {
  background-position: -520px -1000px !important;
}

.r-3095 {
  background-position: -560px -1000px !important;
}

.r-3093 {
  background-position: -600px -1000px !important;
}

.r-3092 {
  background-position: -640px -1000px !important;
}

.r-3091 {
  background-position: -680px -1000px !important;
}

.r-3090 {
  background-position: -720px -1000px !important;
}

.r-3089 {
  background-position: -760px -1000px !important;
}

.r-3088 {
  background-position: -800px -1000px !important;
}

.r-3087 {
  background-position: -840px -1000px !important;
}

.r-3086 {
  background-position: -880px -1000px !important;
}

.r-3085 {
  background-position: -920px -1000px !important;
}

.r-3084 {
  background-position: -960px -1000px !important;
}

.r-3083 {
  background-position: 0 -1040px !important;
}

.r-3082 {
  background-position: -40px -1040px !important;
}

.r-3081 {
  background-position: -80px -1040px !important;
}

.r-3080 {
  background-position: -120px -1040px !important;
}

.r-3079 {
  background-position: -160px -1040px !important;
}

.r-3078 {
  background-position: -200px -1040px !important;
}

.r-3077 {
  background-position: -240px -1040px !important;
}

.r-3076 {
  background-position: -280px -1040px !important;
}

.r-3075 {
  background-position: -320px -1040px !important;
}

.r-3074 {
  background-position: -360px -1040px !important;
}

.r-3073 {
  background-position: -400px -1040px !important;
}

.r-3072 {
  background-position: -440px -1040px !important;
}

.r-3071 {
  background-position: -480px -1040px !important;
}

.r-3070 {
  background-position: -520px -1040px !important;
}

.r-3069 {
  background-position: -560px -1040px !important;
}

.r-3068 {
  background-position: -600px -1040px !important;
}

.r-3067 {
  background-position: -640px -1040px !important;
}

.r-3066 {
  background-position: -680px -1040px !important;
}

.r-3065 {
  background-position: -720px -1040px !important;
}

.r-3064 {
  background-position: -760px -1040px !important;
}

.r-3063 {
  background-position: -800px -1040px !important;
}

.r-3062 {
  background-position: -840px -1040px !important;
}

.r-3061 {
  background-position: -880px -1040px !important;
}

.r-3060 {
  background-position: -920px -1040px !important;
}

.r-3059 {
  background-position: -960px -1040px !important;
}

.r-3058 {
  background-position: 0 -1080px !important;
}

.r-3057 {
  background-position: -40px -1080px !important;
}

.r-3056 {
  background-position: -80px -1080px !important;
}

.r-3055 {
  background-position: -120px -1080px !important;
}

.r-3054 {
  background-position: -160px -1080px !important;
}

.r-3053 {
  background-position: -200px -1080px !important;
}

.r-3052 {
  background-position: -240px -1080px !important;
}

.r-3051 {
  background-position: -280px -1080px !important;
}

.r-3050 {
  background-position: -320px -1080px !important;
}

.r-3049 {
  background-position: -360px -1080px !important;
}

.r-3048 {
  background-position: -400px -1080px !important;
}

.r-3047 {
  background-position: -440px -1080px !important;
}

.r-3046 {
  background-position: -480px -1080px !important;
}

.r-3045 {
  background-position: -520px -1080px !important;
}

.r-3044 {
  background-position: -560px -1080px !important;
}

.r-3043 {
  background-position: -600px -1080px !important;
}

.r-3042 {
  background-position: -640px -1080px !important;
}

.r-3041 {
  background-position: -680px -1080px !important;
}

.r-3040 {
  background-position: -720px -1080px !important;
}

.r-3039 {
  background-position: -760px -1080px !important;
}

.r-3038 {
  background-position: -800px -1080px !important;
}

.r-3037 {
  background-position: -840px -1080px !important;
}

.r-3036 {
  background-position: -880px -1080px !important;
}

.r-3035 {
  background-position: -920px -1080px !important;
}

.r-3034 {
  background-position: -960px -1080px !important;
}

.r-3033 {
  background-position: 0 -1120px !important;
}

.r-3032 {
  background-position: -40px -1120px !important;
}

.r-3031 {
  background-position: -80px -1120px !important;
}

.r-3030 {
  background-position: -120px -1120px !important;
}

.r-3029 {
  background-position: -160px -1120px !important;
}

.r-3028 {
  background-position: -200px -1120px !important;
}

.r-3027 {
  background-position: -240px -1120px !important;
}

.r-3026 {
  background-position: -280px -1120px !important;
}

.r-3025 {
  background-position: -320px -1120px !important;
}

.r-3024 {
  background-position: -360px -1120px !important;
}

.r-3023 {
  background-position: -400px -1120px !important;
}

.r-3022 {
  background-position: -440px -1120px !important;
}

.r-3021 {
  background-position: -480px -1120px !important;
}

.r-3020 {
  background-position: -520px -1120px !important;
}

.r-3019 {
  background-position: -560px -1120px !important;
}

.r-3018 {
  background-position: -600px -1120px !important;
}

.r-3017 {
  background-position: -640px -1120px !important;
}

.r-3016 {
  background-position: -680px -1120px !important;
}

.r-3015 {
  background-position: -720px -1120px !important;
}

.r-3014 {
  background-position: -760px -1120px !important;
}

.r-3013 {
  background-position: -800px -1120px !important;
}

.r-3012 {
  background-position: -840px -1120px !important;
}

.r-3011 {
  background-position: -880px -1120px !important;
}

.r-3010 {
  background-position: -920px -1120px !important;
}

.r-3009 {
  background-position: -960px -1120px !important;
}

.r-3008 {
  background-position: 0 -1160px !important;
}

.r-3007 {
  background-position: -40px -1160px !important;
}

.r-3006 {
  background-position: -80px -1160px !important;
}

.r-3005 {
  background-position: -120px -1160px !important;
}

.r-3004 {
  background-position: -160px -1160px !important;
}

.r-3003 {
  background-position: -200px -1160px !important;
}

.r-3002 {
  background-position: -240px -1160px !important;
}

.r-3001 {
  background-position: -280px -1160px !important;
}

.r-3000 {
  background-position: -320px -1160px !important;
}

.r-2999 {
  background-position: -360px -1160px !important;
}

.r-2998 {
  background-position: -400px -1160px !important;
}

.r-2997 {
  background-position: -440px -1160px !important;
}

.r-2996 {
  background-position: -480px -1160px !important;
}

.r-2995 {
  background-position: -520px -1160px !important;
}

.r-2994 {
  background-position: -560px -1160px !important;
}

.r-2993 {
  background-position: -600px -1160px !important;
}

.r-2992 {
  background-position: -640px -1160px !important;
}

.r-2990 {
  background-position: -680px -1160px !important;
}

.r-2989 {
  background-position: -720px -1160px !important;
}

.r-2988 {
  background-position: -760px -1160px !important;
}

.r-2987 {
  background-position: -800px -1160px !important;
}

.r-2986 {
  background-position: -840px -1160px !important;
}

.r-2985 {
  background-position: -880px -1160px !important;
}

.r-2983 {
  background-position: -920px -1160px !important;
}

.r-2982 {
  background-position: -960px -1160px !important;
}

.r-2981 {
  background-position: 0 -1200px !important;
}

.r-2980 {
  background-position: -40px -1200px !important;
}

.r-2979 {
  background-position: -80px -1200px !important;
}

.r-2978 {
  background-position: -120px -1200px !important;
}

.r-2977 {
  background-position: -160px -1200px !important;
}

.r-2976 {
  background-position: -200px -1200px !important;
}

.r-2975 {
  background-position: -240px -1200px !important;
}

.r-2974 {
  background-position: -280px -1200px !important;
}

.r-2973 {
  background-position: -320px -1200px !important;
}

.r-2972 {
  background-position: -360px -1200px !important;
}

.r-2971 {
  background-position: -400px -1200px !important;
}

.r-2970 {
  background-position: -440px -1200px !important;
}

.r-2969 {
  background-position: -480px -1200px !important;
}

.r-2968 {
  background-position: -520px -1200px !important;
}

.r-2967 {
  background-position: -560px -1200px !important;
}

.r-2966 {
  background-position: -600px -1200px !important;
}

.r-2965 {
  background-position: -640px -1200px !important;
}

.r-2964 {
  background-position: -680px -1200px !important;
}

.r-2963 {
  background-position: -720px -1200px !important;
}

.r-2962 {
  background-position: -760px -1200px !important;
}

.r-2961 {
  background-position: -800px -1200px !important;
}

.r-2960 {
  background-position: -840px -1200px !important;
}

.r-2959 {
  background-position: -880px -1200px !important;
}

.r-2958 {
  background-position: -920px -1200px !important;
}

.r-2957 {
  background-position: -960px -1200px !important;
}

.r-2956 {
  background-position: 0 -1240px !important;
}

.r-2955 {
  background-position: -40px -1240px !important;
}

.r-2954 {
  background-position: -80px -1240px !important;
}

.r-2953 {
  background-position: -120px -1240px !important;
}

.r-2952 {
  background-position: -160px -1240px !important;
}

.r-2951 {
  background-position: -200px -1240px !important;
}

.r-2950 {
  background-position: -240px -1240px !important;
}

.r-2949 {
  background-position: -280px -1240px !important;
}

.r-2948 {
  background-position: -320px -1240px !important;
}

.r-2947 {
  background-position: -360px -1240px !important;
}

.r-2946 {
  background-position: -400px -1240px !important;
}

.r-2945 {
  background-position: -440px -1240px !important;
}

.r-2944 {
  background-position: -480px -1240px !important;
}

.r-2943 {
  background-position: -520px -1240px !important;
}

.r-2942 {
  background-position: -560px -1240px !important;
}

.r-2941 {
  background-position: -600px -1240px !important;
}

.r-2940 {
  background-position: -640px -1240px !important;
}

.r-2939 {
  background-position: -680px -1240px !important;
}

.r-2938 {
  background-position: -720px -1240px !important;
}

.r-2937 {
  background-position: -760px -1240px !important;
}

.r-2936 {
  background-position: -800px -1240px !important;
}

.r-2935 {
  background-position: -840px -1240px !important;
}

.r-2934 {
  background-position: -880px -1240px !important;
}

.r-2933 {
  background-position: -920px -1240px !important;
}

.r-2932 {
  background-position: -960px -1240px !important;
}

.r-2931 {
  background-position: 0 -1280px !important;
}

.r-2930 {
  background-position: -40px -1280px !important;
}

.r-2929 {
  background-position: -80px -1280px !important;
}

.r-2927 {
  background-position: -120px -1280px !important;
}

.r-2926 {
  background-position: -160px -1280px !important;
}

.r-2925 {
  background-position: -200px -1280px !important;
}

.r-2924 {
  background-position: -240px -1280px !important;
}

.r-2923 {
  background-position: -280px -1280px !important;
}

.r-2922 {
  background-position: -320px -1280px !important;
}

.r-2921 {
  background-position: -360px -1280px !important;
}

.r-2920 {
  background-position: -400px -1280px !important;
}

.r-2919 {
  background-position: -440px -1280px !important;
}

.r-2918 {
  background-position: -480px -1280px !important;
}

.r-2917 {
  background-position: -520px -1280px !important;
}

.r-2916 {
  background-position: -560px -1280px !important;
}

.r-2915 {
  background-position: -600px -1280px !important;
}

.r-2914 {
  background-position: -640px -1280px !important;
}

.r-2913 {
  background-position: -680px -1280px !important;
}

.r-2912 {
  background-position: -720px -1280px !important;
}

.r-2911 {
  background-position: -760px -1280px !important;
}

.r-2910 {
  background-position: -800px -1280px !important;
}

.r-2909 {
  background-position: -840px -1280px !important;
}

.r-2908 {
  background-position: -880px -1280px !important;
}

.r-2907 {
  background-position: -920px -1280px !important;
}

.r-2906 {
  background-position: -960px -1280px !important;
}

.r-2905 {
  background-position: 0 -1320px !important;
}

.r-2904 {
  background-position: -40px -1320px !important;
}

.r-2903 {
  background-position: -80px -1320px !important;
}

.r-2902 {
  background-position: -120px -1320px !important;
}

.r-2901 {
  background-position: -160px -1320px !important;
}

.r-2900 {
  background-position: -200px -1320px !important;
}

.r-2899 {
  background-position: -240px -1320px !important;
}

.r-2898 {
  background-position: -280px -1320px !important;
}

.r-2897 {
  background-position: -320px -1320px !important;
}

.r-2896 {
  background-position: -360px -1320px !important;
}

.r-2895 {
  background-position: -400px -1320px !important;
}

.r-2894 {
  background-position: -440px -1320px !important;
}

.r-2892 {
  background-position: -480px -1320px !important;
}

.r-2891 {
  background-position: -520px -1320px !important;
}

.r-2890 {
  background-position: -560px -1320px !important;
}

.r-2889 {
  background-position: -600px -1320px !important;
}

.r-2888 {
  background-position: -640px -1320px !important;
}

.r-2887 {
  background-position: -680px -1320px !important;
}

.r-2886 {
  background-position: -720px -1320px !important;
}

.r-2885 {
  background-position: -760px -1320px !important;
}

.r-2884 {
  background-position: -800px -1320px !important;
}

.r-2883 {
  background-position: -840px -1320px !important;
}

.r-2882 {
  background-position: -880px -1320px !important;
}

.r-2881 {
  background-position: -920px -1320px !important;
}

.r-2880 {
  background-position: -960px -1320px !important;
}

.r-2879 {
  background-position: 0 -1360px !important;
}

.r-2878 {
  background-position: -40px -1360px !important;
}

.r-2877 {
  background-position: -80px -1360px !important;
}

.r-2876 {
  background-position: -120px -1360px !important;
}

.r-2875 {
  background-position: -160px -1360px !important;
}

.r-2874 {
  background-position: -200px -1360px !important;
}

.r-2873 {
  background-position: -240px -1360px !important;
}

.r-2872 {
  background-position: -280px -1360px !important;
}

.r-2871 {
  background-position: -320px -1360px !important;
}

.r-2870 {
  background-position: -360px -1360px !important;
}

.r-2869 {
  background-position: -400px -1360px !important;
}

.r-2868 {
  background-position: -440px -1360px !important;
}

.r-2867 {
  background-position: -480px -1360px !important;
}

.r-2866 {
  background-position: -520px -1360px !important;
}

.r-2865 {
  background-position: -560px -1360px !important;
}

.r-2864 {
  background-position: -600px -1360px !important;
}

.r-2863 {
  background-position: -640px -1360px !important;
}

.r-2862 {
  background-position: -680px -1360px !important;
}

.r-2861 {
  background-position: -720px -1360px !important;
}

.r-2860 {
  background-position: -760px -1360px !important;
}

.r-2859 {
  background-position: -800px -1360px !important;
}

.r-2858 {
  background-position: -840px -1360px !important;
}

.r-2857 {
  background-position: -880px -1360px !important;
}

.r-2856 {
  background-position: -920px -1360px !important;
}

.r-2855 {
  background-position: -960px -1360px !important;
}

.r-2854 {
  background-position: 0 -1400px !important;
}

.r-2853 {
  background-position: -40px -1400px !important;
}

.r-2852 {
  background-position: -80px -1400px !important;
}

.r-2851 {
  background-position: -120px -1400px !important;
}

.r-2850 {
  background-position: -160px -1400px !important;
}

.r-2849 {
  background-position: -200px -1400px !important;
}

.r-2848 {
  background-position: -240px -1400px !important;
}

.r-2847 {
  background-position: -280px -1400px !important;
}

.r-2846 {
  background-position: -320px -1400px !important;
}

.r-2845 {
  background-position: -360px -1400px !important;
}

.r-2844 {
  background-position: -400px -1400px !important;
}

.r-2843 {
  background-position: -440px -1400px !important;
}

.r-2842 {
  background-position: -480px -1400px !important;
}

.r-2841 {
  background-position: -520px -1400px !important;
}

.r-2840 {
  background-position: -560px -1400px !important;
}

.r-2839 {
  background-position: -600px -1400px !important;
}

.r-2838 {
  background-position: -640px -1400px !important;
}

.r-2837 {
  background-position: -680px -1400px !important;
}

.r-2836 {
  background-position: -720px -1400px !important;
}

.r-2835 {
  background-position: -760px -1400px !important;
}

.r-2834 {
  background-position: -800px -1400px !important;
}

.r-2833 {
  background-position: -840px -1400px !important;
}

.r-2832 {
  background-position: -880px -1400px !important;
}

.r-2831 {
  background-position: -920px -1400px !important;
}

.r-2830 {
  background-position: -960px -1400px !important;
}

.r-2829 {
  background-position: 0 -1440px !important;
}

.r-2828 {
  background-position: -40px -1440px !important;
}

.r-2827 {
  background-position: -80px -1440px !important;
}

.r-2826 {
  background-position: -120px -1440px !important;
}

.r-2825 {
  background-position: -160px -1440px !important;
}

.r-2824 {
  background-position: -200px -1440px !important;
}

.r-2823 {
  background-position: -240px -1440px !important;
}

.r-2821 {
  background-position: -280px -1440px !important;
}

.r-2820 {
  background-position: -320px -1440px !important;
}

.r-2819 {
  background-position: -360px -1440px !important;
}

.r-2818 {
  background-position: -400px -1440px !important;
}

.r-2817 {
  background-position: -440px -1440px !important;
}

.r-2816 {
  background-position: -480px -1440px !important;
}

.r-2815 {
  background-position: -520px -1440px !important;
}

.r-2814 {
  background-position: -560px -1440px !important;
}

.r-2813 {
  background-position: -600px -1440px !important;
}

.r-2812 {
  background-position: -640px -1440px !important;
}

.r-2811 {
  background-position: -680px -1440px !important;
}

.r-2810 {
  background-position: -720px -1440px !important;
}

.r-2809 {
  background-position: -760px -1440px !important;
}

.r-2808 {
  background-position: -800px -1440px !important;
}

.r-2807 {
  background-position: -840px -1440px !important;
}

.r-2806 {
  background-position: -880px -1440px !important;
}

.r-2805 {
  background-position: -920px -1440px !important;
}

.r-2804 {
  background-position: -960px -1440px !important;
}

.r-2803 {
  background-position: 0 -1480px !important;
}

.r-2802 {
  background-position: -40px -1480px !important;
}

.r-2801 {
  background-position: -80px -1480px !important;
}

.r-2800 {
  background-position: -120px -1480px !important;
}

.r-2799 {
  background-position: -160px -1480px !important;
}

.r-2798 {
  background-position: -200px -1480px !important;
}

.r-2797 {
  background-position: -240px -1480px !important;
}

.r-2796 {
  background-position: -280px -1480px !important;
}

.r-2795 {
  background-position: -320px -1480px !important;
}

.r-2794 {
  background-position: -360px -1480px !important;
}

.r-2793 {
  background-position: -400px -1480px !important;
}

.r-2792 {
  background-position: -440px -1480px !important;
}

.r-2791 {
  background-position: -480px -1480px !important;
}

.r-2790 {
  background-position: -520px -1480px !important;
}

.r-2789 {
  background-position: -560px -1480px !important;
}

.r-2788 {
  background-position: -600px -1480px !important;
}

.r-2787 {
  background-position: -640px -1480px !important;
}

.r-2786 {
  background-position: -680px -1480px !important;
}

.r-2785 {
  background-position: -720px -1480px !important;
}

.r-2784 {
  background-position: -760px -1480px !important;
}

.r-2783 {
  background-position: -800px -1480px !important;
}

.r-2782 {
  background-position: -840px -1480px !important;
}

.r-2781 {
  background-position: -880px -1480px !important;
}

.r-2780 {
  background-position: -920px -1480px !important;
}

.r-2779 {
  background-position: -960px -1480px !important;
}

.r-2778 {
  background-position: 0 -1520px !important;
}

.r-2777 {
  background-position: -40px -1520px !important;
}

.r-2776 {
  background-position: -80px -1520px !important;
}

.r-2775 {
  background-position: -120px -1520px !important;
}

.r-2774 {
  background-position: -160px -1520px !important;
}

.r-2773 {
  background-position: -200px -1520px !important;
}

.r-2772 {
  background-position: -240px -1520px !important;
}

.r-2771 {
  background-position: -280px -1520px !important;
}

.r-2770 {
  background-position: -320px -1520px !important;
}

.r-2769 {
  background-position: -360px -1520px !important;
}

.r-2768 {
  background-position: -400px -1520px !important;
}

.r-2767 {
  background-position: -440px -1520px !important;
}

.r-2766 {
  background-position: -480px -1520px !important;
}

.r-2765 {
  background-position: -520px -1520px !important;
}

.r-2764 {
  background-position: -560px -1520px !important;
}

.r-2763 {
  background-position: -600px -1520px !important;
}

.r-2762 {
  background-position: -640px -1520px !important;
}

.r-2761 {
  background-position: -680px -1520px !important;
}

.r-2760 {
  background-position: -720px -1520px !important;
}

.r-2759 {
  background-position: -760px -1520px !important;
}

.r-2758 {
  background-position: -800px -1520px !important;
}

.r-2757 {
  background-position: -840px -1520px !important;
}

.r-2756 {
  background-position: -880px -1520px !important;
}

.r-2755 {
  background-position: -920px -1520px !important;
}

.r-2754 {
  background-position: -960px -1520px !important;
}

.r-2753 {
  background-position: 0 -1560px !important;
}

.r-2752 {
  background-position: -40px -1560px !important;
}

.r-2751 {
  background-position: -80px -1560px !important;
}

.r-2750 {
  background-position: -120px -1560px !important;
}

.r-2749 {
  background-position: -160px -1560px !important;
}

.r-2748 {
  background-position: -200px -1560px !important;
}

.r-2747 {
  background-position: -240px -1560px !important;
}

.r-2746 {
  background-position: -280px -1560px !important;
}

.r-2745 {
  background-position: -320px -1560px !important;
}

.r-2744 {
  background-position: -360px -1560px !important;
}

.r-2743 {
  background-position: -400px -1560px !important;
}

.r-2742 {
  background-position: -440px -1560px !important;
}

.r-2741 {
  background-position: -480px -1560px !important;
}

.r-2740 {
  background-position: -520px -1560px !important;
}

.r-2739 {
  background-position: -560px -1560px !important;
}

.r-2738 {
  background-position: -600px -1560px !important;
}

.r-2737 {
  background-position: -640px -1560px !important;
}

.r-2736 {
  background-position: -680px -1560px !important;
}

.r-2735 {
  background-position: -720px -1560px !important;
}

.r-2734 {
  background-position: -760px -1560px !important;
}

.r-2733 {
  background-position: -800px -1560px !important;
}

.r-2732 {
  background-position: -840px -1560px !important;
}

.r-2731 {
  background-position: -880px -1560px !important;
}

.r-2730 {
  background-position: -920px -1560px !important;
}

.r-2729 {
  background-position: -960px -1560px !important;
}

.r-2728 {
  background-position: 0 -1600px !important;
}

.r-2727 {
  background-position: -40px -1600px !important;
}

.r-2726 {
  background-position: -80px -1600px !important;
}

.r-2725 {
  background-position: -120px -1600px !important;
}

.r-2724 {
  background-position: -160px -1600px !important;
}

.r-2723 {
  background-position: -200px -1600px !important;
}

.r-2722 {
  background-position: -240px -1600px !important;
}

.r-2721 {
  background-position: -280px -1600px !important;
}

.r-2720 {
  background-position: -320px -1600px !important;
}

.r-2719 {
  background-position: -360px -1600px !important;
}

.r-2718 {
  background-position: -400px -1600px !important;
}

.r-2717 {
  background-position: -440px -1600px !important;
}

.r-2716 {
  background-position: -480px -1600px !important;
}

.r-2715 {
  background-position: -520px -1600px !important;
}

.r-2714 {
  background-position: -560px -1600px !important;
}

.r-2713 {
  background-position: -600px -1600px !important;
}

.r-2712 {
  background-position: -640px -1600px !important;
}

.r-2711 {
  background-position: -680px -1600px !important;
}

.r-2710 {
  background-position: -720px -1600px !important;
}

.r-2709 {
  background-position: -760px -1600px !important;
}

.r-2708 {
  background-position: -800px -1600px !important;
}

.r-2707 {
  background-position: -840px -1600px !important;
}

.r-2706 {
  background-position: -880px -1600px !important;
}

.r-2705 {
  background-position: -920px -1600px !important;
}

.r-2704 {
  background-position: -960px -1600px !important;
}

.r-2703 {
  background-position: 0 -1640px !important;
}

.r-2702 {
  background-position: -40px -1640px !important;
}

.r-2701 {
  background-position: -80px -1640px !important;
}

.r-2700 {
  background-position: -120px -1640px !important;
}

.r-2699 {
  background-position: -160px -1640px !important;
}

.r-2698 {
  background-position: -200px -1640px !important;
}

.r-2697 {
  background-position: -240px -1640px !important;
}

.r-2696 {
  background-position: -280px -1640px !important;
}

.r-2695 {
  background-position: -320px -1640px !important;
}

.r-2694 {
  background-position: -360px -1640px !important;
}

.r-2693 {
  background-position: -400px -1640px !important;
}

.r-2692 {
  background-position: -440px -1640px !important;
}

.r-2691 {
  background-position: -480px -1640px !important;
}

.r-2688 {
  background-position: -520px -1640px !important;
}

.r-2687 {
  background-position: -560px -1640px !important;
}

.r-2686 {
  background-position: -600px -1640px !important;
}

.r-2685 {
  background-position: -640px -1640px !important;
}

.r-2684 {
  background-position: -680px -1640px !important;
}

.r-2683 {
  background-position: -720px -1640px !important;
}

.r-2682 {
  background-position: -760px -1640px !important;
}

.r-2681 {
  background-position: -800px -1640px !important;
}

.r-2680 {
  background-position: -840px -1640px !important;
}

.r-2679 {
  background-position: -880px -1640px !important;
}

.r-2678 {
  background-position: -920px -1640px !important;
}

.r-2677 {
  background-position: -960px -1640px !important;
}

.r-2676 {
  background-position: 0 -1680px !important;
}

.r-2675 {
  background-position: -40px -1680px !important;
}

.r-2674 {
  background-position: -80px -1680px !important;
}

.r-2673 {
  background-position: -120px -1680px !important;
}

.r-2672 {
  background-position: -160px -1680px !important;
}

.r-2671 {
  background-position: -200px -1680px !important;
}

.r-2670 {
  background-position: -240px -1680px !important;
}

.r-2669 {
  background-position: -280px -1680px !important;
}

.r-2668 {
  background-position: -320px -1680px !important;
}

.r-2667 {
  background-position: -360px -1680px !important;
}

.r-2666 {
  background-position: -400px -1680px !important;
}

.r-2665 {
  background-position: -440px -1680px !important;
}

.r-2664 {
  background-position: -480px -1680px !important;
}

.r-2663 {
  background-position: -520px -1680px !important;
}

.r-2662 {
  background-position: -560px -1680px !important;
}

.r-2661 {
  background-position: -600px -1680px !important;
}

.r-2660 {
  background-position: -640px -1680px !important;
}

.r-2659 {
  background-position: -680px -1680px !important;
}

.r-2658 {
  background-position: -720px -1680px !important;
}

.r-2657 {
  background-position: -760px -1680px !important;
}

.r-2656 {
  background-position: -800px -1680px !important;
}

.r-2655 {
  background-position: -840px -1680px !important;
}

.r-2654 {
  background-position: -880px -1680px !important;
}

.r-2653 {
  background-position: -920px -1680px !important;
}

.r-2652 {
  background-position: -960px -1680px !important;
}

.r-2651 {
  background-position: 0 -1720px !important;
}

.r-2650 {
  background-position: -40px -1720px !important;
}

.r-2649 {
  background-position: -80px -1720px !important;
}

.r-2648 {
  background-position: -120px -1720px !important;
}

.r-2647 {
  background-position: -160px -1720px !important;
}

.r-2646 {
  background-position: -200px -1720px !important;
}

.r-2645 {
  background-position: -240px -1720px !important;
}

.r-2644 {
  background-position: -280px -1720px !important;
}

.r-2643 {
  background-position: -320px -1720px !important;
}

.r-2642 {
  background-position: -360px -1720px !important;
}

.r-2641 {
  background-position: -400px -1720px !important;
}

.r-2640 {
  background-position: -440px -1720px !important;
}

.r-2639 {
  background-position: -480px -1720px !important;
}

.r-2638 {
  background-position: -520px -1720px !important;
}

.r-2637 {
  background-position: -560px -1720px !important;
}

.r-2636 {
  background-position: -600px -1720px !important;
}

.r-2635 {
  background-position: -640px -1720px !important;
}

.r-2634 {
  background-position: -680px -1720px !important;
}

.r-2633 {
  background-position: -720px -1720px !important;
}

.r-2632 {
  background-position: -760px -1720px !important;
}

.r-2631 {
  background-position: -800px -1720px !important;
}

.r-2630 {
  background-position: -840px -1720px !important;
}

.r-2629 {
  background-position: -880px -1720px !important;
}

.r-2628 {
  background-position: -920px -1720px !important;
}

.r-2627 {
  background-position: -960px -1720px !important;
}

.r-2626 {
  background-position: 0 -1760px !important;
}

.r-2625 {
  background-position: -40px -1760px !important;
}

.r-2624 {
  background-position: -80px -1760px !important;
}

.r-2623 {
  background-position: -120px -1760px !important;
}

.r-2622 {
  background-position: -160px -1760px !important;
}

.r-2621 {
  background-position: -200px -1760px !important;
}

.r-2620 {
  background-position: -240px -1760px !important;
}

.r-2619 {
  background-position: -280px -1760px !important;
}

.r-2618 {
  background-position: -320px -1760px !important;
}

.r-2617 {
  background-position: -360px -1760px !important;
}

.r-2616 {
  background-position: -400px -1760px !important;
}

.r-2615 {
  background-position: -440px -1760px !important;
}

.r-2614 {
  background-position: -480px -1760px !important;
}

.r-2613 {
  background-position: -520px -1760px !important;
}

.r-2612 {
  background-position: -560px -1760px !important;
}

.r-2611 {
  background-position: -600px -1760px !important;
}

.r-2610 {
  background-position: -640px -1760px !important;
}

.r-2608 {
  background-position: -680px -1760px !important;
}

.r-2607 {
  background-position: -720px -1760px !important;
}

.r-2606 {
  background-position: -760px -1760px !important;
}

.r-2605 {
  background-position: -800px -1760px !important;
}

.r-2604 {
  background-position: -840px -1760px !important;
}

.r-2603 {
  background-position: -880px -1760px !important;
}

.r-2602 {
  background-position: -920px -1760px !important;
}

.r-2601 {
  background-position: -960px -1760px !important;
}

.r-2600 {
  background-position: 0 -1800px !important;
}

.r-2599 {
  background-position: -40px -1800px !important;
}

.r-2598 {
  background-position: -80px -1800px !important;
}

.r-2597 {
  background-position: -120px -1800px !important;
}

.r-2596 {
  background-position: -160px -1800px !important;
}

.r-2595 {
  background-position: -200px -1800px !important;
}

.r-2594 {
  background-position: -240px -1800px !important;
}

.r-2593 {
  background-position: -280px -1800px !important;
}

.r-2592 {
  background-position: -320px -1800px !important;
}

.r-2591 {
  background-position: -360px -1800px !important;
}

.r-2590 {
  background-position: -400px -1800px !important;
}

.r-2589 {
  background-position: -440px -1800px !important;
}

.r-2588 {
  background-position: -480px -1800px !important;
}

.r-2587 {
  background-position: -520px -1800px !important;
}

.r-2586 {
  background-position: -560px -1800px !important;
}

.r-2585 {
  background-position: -600px -1800px !important;
}

.r-2584 {
  background-position: -640px -1800px !important;
}

.r-2583 {
  background-position: -680px -1800px !important;
}

.r-2582 {
  background-position: -720px -1800px !important;
}

.r-2581 {
  background-position: -760px -1800px !important;
}

.r-2580 {
  background-position: -800px -1800px !important;
}

.r-2579 {
  background-position: -840px -1800px !important;
}

.r-2578 {
  background-position: -880px -1800px !important;
}

.r-2577 {
  background-position: -920px -1800px !important;
}

.r-2576 {
  background-position: -960px -1800px !important;
}

.r-2575 {
  background-position: 0 -1840px !important;
}

.r-2574 {
  background-position: -40px -1840px !important;
}

.r-2573 {
  background-position: -80px -1840px !important;
}

.r-2572 {
  background-position: -120px -1840px !important;
}

.r-2571 {
  background-position: -160px -1840px !important;
}

.r-2570 {
  background-position: -200px -1840px !important;
}

.r-2569 {
  background-position: -240px -1840px !important;
}

.r-2568 {
  background-position: -280px -1840px !important;
}

.r-2567 {
  background-position: -320px -1840px !important;
}

.r-2566 {
  background-position: -360px -1840px !important;
}

.r-2565 {
  background-position: -400px -1840px !important;
}

.r-2564 {
  background-position: -440px -1840px !important;
}

.r-2563 {
  background-position: -480px -1840px !important;
}

.r-2562 {
  background-position: -520px -1840px !important;
}

.r-2561 {
  background-position: -560px -1840px !important;
}

.r-2560 {
  background-position: -600px -1840px !important;
}

.r-2559 {
  background-position: -640px -1840px !important;
}

.r-2558 {
  background-position: -680px -1840px !important;
}

.r-2557 {
  background-position: -720px -1840px !important;
}

.r-2556 {
  background-position: -760px -1840px !important;
}

.r-2555 {
  background-position: -800px -1840px !important;
}

.r-2554 {
  background-position: -840px -1840px !important;
}

.r-2553 {
  background-position: -880px -1840px !important;
}

.r-2552 {
  background-position: -920px -1840px !important;
}

.r-2551 {
  background-position: -960px -1840px !important;
}

.r-2550 {
  background-position: 0 -1880px !important;
}

.r-2549 {
  background-position: -40px -1880px !important;
}

.r-2548 {
  background-position: -80px -1880px !important;
}

.r-2547 {
  background-position: -120px -1880px !important;
}

.r-2546 {
  background-position: -160px -1880px !important;
}

.r-2545 {
  background-position: -200px -1880px !important;
}

.r-2544 {
  background-position: -240px -1880px !important;
}

.r-2543 {
  background-position: -280px -1880px !important;
}

.r-2542 {
  background-position: -320px -1880px !important;
}

.r-2541 {
  background-position: -360px -1880px !important;
}

.r-2540 {
  background-position: -400px -1880px !important;
}

.r-2539 {
  background-position: -440px -1880px !important;
}

.r-2538 {
  background-position: -480px -1880px !important;
}

.r-2537 {
  background-position: -520px -1880px !important;
}

.r-2536 {
  background-position: -560px -1880px !important;
}

.r-2535 {
  background-position: -600px -1880px !important;
}

.r-2534 {
  background-position: -640px -1880px !important;
}

.r-2533 {
  background-position: -680px -1880px !important;
}

.r-2532 {
  background-position: -720px -1880px !important;
}

.r-2531 {
  background-position: -760px -1880px !important;
}

.r-2530 {
  background-position: -800px -1880px !important;
}

.r-2529 {
  background-position: -840px -1880px !important;
}

.r-2528 {
  background-position: -880px -1880px !important;
}

.r-2527 {
  background-position: -920px -1880px !important;
}

.r-2526 {
  background-position: -960px -1880px !important;
}

.r-2525 {
  background-position: 0 -1920px !important;
}

.r-2524 {
  background-position: -40px -1920px !important;
}

.r-2523 {
  background-position: -80px -1920px !important;
}

.r-2521 {
  background-position: -120px -1920px !important;
}

.r-2520 {
  background-position: -160px -1920px !important;
}

.r-2519 {
  background-position: -200px -1920px !important;
}

.r-2518 {
  background-position: -240px -1920px !important;
}

.r-2517 {
  background-position: -280px -1920px !important;
}

.r-2516 {
  background-position: -320px -1920px !important;
}

.r-2515 {
  background-position: -360px -1920px !important;
}

.r-2514 {
  background-position: -400px -1920px !important;
}

.r-2513 {
  background-position: -440px -1920px !important;
}

.r-2512 {
  background-position: -480px -1920px !important;
}

.r-2511 {
  background-position: -520px -1920px !important;
}

.r-2510 {
  background-position: -560px -1920px !important;
}

.r-2509 {
  background-position: -600px -1920px !important;
}

.r-2508 {
  background-position: -640px -1920px !important;
}

.r-2507 {
  background-position: -680px -1920px !important;
}

.r-2506 {
  background-position: -720px -1920px !important;
}

.r-2505 {
  background-position: -760px -1920px !important;
}

.r-2504 {
  background-position: -800px -1920px !important;
}

.r-2503 {
  background-position: -840px -1920px !important;
}

.r-2502 {
  background-position: -880px -1920px !important;
}

.r-2501 {
  background-position: -920px -1920px !important;
}

.r-2500 {
  background-position: -960px -1920px !important;
}

.r-2499 {
  background-position: 0 -1960px !important;
}

.r-2498 {
  background-position: -40px -1960px !important;
}

.r-2497 {
  background-position: -80px -1960px !important;
}

.r-2496 {
  background-position: -120px -1960px !important;
}

.r-2495 {
  background-position: -160px -1960px !important;
}

.r-2494 {
  background-position: -200px -1960px !important;
}

.r-2493 {
  background-position: -240px -1960px !important;
}

.r-2492 {
  background-position: -280px -1960px !important;
}

.r-2491 {
  background-position: -320px -1960px !important;
}

.r-2490 {
  background-position: -360px -1960px !important;
}

.r-2489 {
  background-position: -400px -1960px !important;
}

.r-2488 {
  background-position: -440px -1960px !important;
}

.r-2487 {
  background-position: -480px -1960px !important;
}

.r-2485 {
  background-position: -520px -1960px !important;
}

.r-2484 {
  background-position: -560px -1960px !important;
}

.r-2481 {
  background-position: -600px -1960px !important;
}

.r-2480 {
  background-position: -640px -1960px !important;
}

.r-2479 {
  background-position: -680px -1960px !important;
}

.r-2478 {
  background-position: -720px -1960px !important;
}

.r-2477 {
  background-position: -760px -1960px !important;
}

.r-2476 {
  background-position: -800px -1960px !important;
}

.r-2475 {
  background-position: -840px -1960px !important;
}

.r-2474 {
  background-position: -880px -1960px !important;
}

.r-2473 {
  background-position: -920px -1960px !important;
}

.r-2472 {
  background-position: -960px -1960px !important;
}

.r-2471 {
  background-position: 0 -2000px !important;
}

.r-2470 {
  background-position: -40px -2000px !important;
}

.r-2469 {
  background-position: -80px -2000px !important;
}

.r-2468 {
  background-position: -120px -2000px !important;
}

.r-2467 {
  background-position: -160px -2000px !important;
}

.r-2466 {
  background-position: -200px -2000px !important;
}

.r-2465 {
  background-position: -240px -2000px !important;
}

.r-2464 {
  background-position: -280px -2000px !important;
}

.r-2463 {
  background-position: -320px -2000px !important;
}

.r-2462 {
  background-position: -360px -2000px !important;
}

.r-2461 {
  background-position: -400px -2000px !important;
}

.r-2460 {
  background-position: -440px -2000px !important;
}

.r-2459 {
  background-position: -480px -2000px !important;
}

.r-2458 {
  background-position: -520px -2000px !important;
}

.r-2457 {
  background-position: -560px -2000px !important;
}

.r-2456 {
  background-position: -600px -2000px !important;
}

.r-2455 {
  background-position: -640px -2000px !important;
}

.r-2454 {
  background-position: -680px -2000px !important;
}

.r-2453 {
  background-position: -720px -2000px !important;
}

.r-2452 {
  background-position: -760px -2000px !important;
}

.r-2451 {
  background-position: -800px -2000px !important;
}

.r-2450 {
  background-position: -840px -2000px !important;
}

.r-2449 {
  background-position: -880px -2000px !important;
}

.r-2448 {
  background-position: -920px -2000px !important;
}

.r-2447 {
  background-position: -960px -2000px !important;
}

.r-2446 {
  background-position: 0 -2040px !important;
}

.r-2445 {
  background-position: -40px -2040px !important;
}

.r-2444 {
  background-position: -80px -2040px !important;
}

.r-2443 {
  background-position: -120px -2040px !important;
}

.r-2442 {
  background-position: -160px -2040px !important;
}

.r-2441 {
  background-position: -200px -2040px !important;
}

.r-2440 {
  background-position: -240px -2040px !important;
}

.r-2439 {
  background-position: -280px -2040px !important;
}

.r-2438 {
  background-position: -320px -2040px !important;
}

.r-2437 {
  background-position: -360px -2040px !important;
}

.r-2436 {
  background-position: -400px -2040px !important;
}

.r-2435 {
  background-position: -440px -2040px !important;
}

.r-2434 {
  background-position: -480px -2040px !important;
}

.r-2433 {
  background-position: -520px -2040px !important;
}

.r-2432 {
  background-position: -560px -2040px !important;
}

.r-2431 {
  background-position: -600px -2040px !important;
}

.r-2430 {
  background-position: -640px -2040px !important;
}

.r-2429 {
  background-position: -680px -2040px !important;
}

.r-2428 {
  background-position: -720px -2040px !important;
}

.r-2427 {
  background-position: -760px -2040px !important;
}

.r-2426 {
  background-position: -800px -2040px !important;
}

.r-2425 {
  background-position: -840px -2040px !important;
}

.r-2424 {
  background-position: -880px -2040px !important;
}

.r-2423 {
  background-position: -920px -2040px !important;
}

.r-2421 {
  background-position: -960px -2040px !important;
}

.r-2420 {
  background-position: 0 -2080px !important;
}

.r-2419 {
  background-position: -40px -2080px !important;
}

.r-2418 {
  background-position: -80px -2080px !important;
}

.r-2417 {
  background-position: -120px -2080px !important;
}

.r-2416 {
  background-position: -160px -2080px !important;
}

.r-2415 {
  background-position: -200px -2080px !important;
}

.r-2414 {
  background-position: -240px -2080px !important;
}

.r-2413 {
  background-position: -280px -2080px !important;
}

.r-2412 {
  background-position: -320px -2080px !important;
}

.r-2411 {
  background-position: -360px -2080px !important;
}

.r-2410 {
  background-position: -400px -2080px !important;
}

.r-2409 {
  background-position: -440px -2080px !important;
}

.r-2408 {
  background-position: -480px -2080px !important;
}

.r-2407 {
  background-position: -520px -2080px !important;
}

.r-2406 {
  background-position: -560px -2080px !important;
}

.r-2405 {
  background-position: -600px -2080px !important;
}

.r-2404 {
  background-position: -640px -2080px !important;
}

.r-2403 {
  background-position: -680px -2080px !important;
}

.r-2402 {
  background-position: -720px -2080px !important;
}

.r-2401 {
  background-position: -760px -2080px !important;
}

.r-2400 {
  background-position: -800px -2080px !important;
}

.r-2399 {
  background-position: -840px -2080px !important;
}

.r-2398 {
  background-position: -880px -2080px !important;
}

.r-2397 {
  background-position: -920px -2080px !important;
}

.r-2396 {
  background-position: -960px -2080px !important;
}

.r-2395 {
  background-position: 0 -2120px !important;
}

.r-2394 {
  background-position: -40px -2120px !important;
}

.r-2393 {
  background-position: -80px -2120px !important;
}

.r-2392 {
  background-position: -120px -2120px !important;
}

.r-2391 {
  background-position: -160px -2120px !important;
}

.r-2390 {
  background-position: -200px -2120px !important;
}

.r-2389 {
  background-position: -240px -2120px !important;
}

.r-2388 {
  background-position: -280px -2120px !important;
}

.r-2387 {
  background-position: -320px -2120px !important;
}

.r-2386 {
  background-position: -360px -2120px !important;
}

.r-2385 {
  background-position: -400px -2120px !important;
}

.r-2384 {
  background-position: -440px -2120px !important;
}

.r-2383 {
  background-position: -480px -2120px !important;
}

.r-2382 {
  background-position: -520px -2120px !important;
}

.r-2381 {
  background-position: -560px -2120px !important;
}

.r-2380 {
  background-position: -600px -2120px !important;
}

.r-2379 {
  background-position: -640px -2120px !important;
}

.r-2378 {
  background-position: -680px -2120px !important;
}

.r-2377 {
  background-position: -720px -2120px !important;
}

.r-2376 {
  background-position: -760px -2120px !important;
}

.r-2375 {
  background-position: -800px -2120px !important;
}

.r-2374 {
  background-position: -840px -2120px !important;
}

.r-2373 {
  background-position: -880px -2120px !important;
}

.r-2372 {
  background-position: -920px -2120px !important;
}

.r-2371 {
  background-position: -960px -2120px !important;
}

.r-2370 {
  background-position: 0 -2160px !important;
}

.r-2369 {
  background-position: -40px -2160px !important;
}

.r-2368 {
  background-position: -80px -2160px !important;
}

.r-2367 {
  background-position: -120px -2160px !important;
}

.r-2366 {
  background-position: -160px -2160px !important;
}

.r-2365 {
  background-position: -200px -2160px !important;
}

.r-2364 {
  background-position: -240px -2160px !important;
}

.r-2363 {
  background-position: -280px -2160px !important;
}

.r-2362 {
  background-position: -320px -2160px !important;
}

.r-2361 {
  background-position: -360px -2160px !important;
}

.r-2360 {
  background-position: -400px -2160px !important;
}

.r-2359 {
  background-position: -440px -2160px !important;
}

.r-2358 {
  background-position: -480px -2160px !important;
}

.r-2357 {
  background-position: -520px -2160px !important;
}

.r-2356 {
  background-position: -560px -2160px !important;
}

.r-2355 {
  background-position: -600px -2160px !important;
}

.r-2354 {
  background-position: -640px -2160px !important;
}

.r-2353 {
  background-position: -680px -2160px !important;
}

.r-2352 {
  background-position: -720px -2160px !important;
}

.r-2351 {
  background-position: -760px -2160px !important;
}

.r-2350 {
  background-position: -800px -2160px !important;
}

.r-2349 {
  background-position: -840px -2160px !important;
}

.r-2348 {
  background-position: -880px -2160px !important;
}

.r-2347 {
  background-position: -920px -2160px !important;
}

.r-2346 {
  background-position: -960px -2160px !important;
}

.r-2345 {
  background-position: 0 -2200px !important;
}

.r-2344 {
  background-position: -40px -2200px !important;
}

.r-2343 {
  background-position: -80px -2200px !important;
}

.r-2342 {
  background-position: -120px -2200px !important;
}

.r-2341 {
  background-position: -160px -2200px !important;
}

.r-2340 {
  background-position: -200px -2200px !important;
}

.r-2339 {
  background-position: -240px -2200px !important;
}

.r-2338 {
  background-position: -280px -2200px !important;
}

.r-2337 {
  background-position: -320px -2200px !important;
}

.r-2336 {
  background-position: -360px -2200px !important;
}

.r-2335 {
  background-position: -400px -2200px !important;
}

.r-2334 {
  background-position: -440px -2200px !important;
}

.r-2333 {
  background-position: -480px -2200px !important;
}

.r-2332 {
  background-position: -520px -2200px !important;
}

.r-2331 {
  background-position: -560px -2200px !important;
}

.r-2330 {
  background-position: -600px -2200px !important;
}

.r-2329 {
  background-position: -640px -2200px !important;
}

.r-2328 {
  background-position: -680px -2200px !important;
}

.r-2327 {
  background-position: -720px -2200px !important;
}

.r-2326 {
  background-position: -760px -2200px !important;
}

.r-2325 {
  background-position: -800px -2200px !important;
}

.r-2324 {
  background-position: -840px -2200px !important;
}

.r-2323 {
  background-position: -880px -2200px !important;
}

.r-2322 {
  background-position: -920px -2200px !important;
}

.r-2321 {
  background-position: -960px -2200px !important;
}

.r-2320 {
  background-position: 0 -2240px !important;
}

.r-2319 {
  background-position: -40px -2240px !important;
}

.r-2318 {
  background-position: -80px -2240px !important;
}

.r-2317 {
  background-position: -120px -2240px !important;
}

.r-2316 {
  background-position: -160px -2240px !important;
}

.r-2315 {
  background-position: -200px -2240px !important;
}

.r-2314 {
  background-position: -240px -2240px !important;
}

.r-2313 {
  background-position: -280px -2240px !important;
}

.r-2312 {
  background-position: -320px -2240px !important;
}

.r-2311 {
  background-position: -360px -2240px !important;
}

.r-2310 {
  background-position: -400px -2240px !important;
}

.r-2309 {
  background-position: -440px -2240px !important;
}

.r-2308 {
  background-position: -480px -2240px !important;
}

.r-2307 {
  background-position: -520px -2240px !important;
}

.r-2306 {
  background-position: -560px -2240px !important;
}

.r-2305 {
  background-position: -600px -2240px !important;
}

.r-2304 {
  background-position: -640px -2240px !important;
}

.r-2303 {
  background-position: -680px -2240px !important;
}

.r-2302 {
  background-position: -720px -2240px !important;
}

.r-2301 {
  background-position: -760px -2240px !important;
}

.r-2300 {
  background-position: -800px -2240px !important;
}

.r-2299 {
  background-position: -840px -2240px !important;
}

.r-2298 {
  background-position: -880px -2240px !important;
}

.r-2297 {
  background-position: -920px -2240px !important;
}

.r-2296 {
  background-position: -960px -2240px !important;
}

.r-2295 {
  background-position: 0 -2280px !important;
}

.r-2293 {
  background-position: -40px -2280px !important;
}

.r-2292 {
  background-position: -80px -2280px !important;
}

.r-2291 {
  background-position: -120px -2280px !important;
}

.r-2290 {
  background-position: -160px -2280px !important;
}

.r-2289 {
  background-position: -200px -2280px !important;
}

.r-2288 {
  background-position: -240px -2280px !important;
}

.r-2287 {
  background-position: -280px -2280px !important;
}

.r-2286 {
  background-position: -320px -2280px !important;
}

.r-2285 {
  background-position: -360px -2280px !important;
}

.r-2284 {
  background-position: -400px -2280px !important;
}

.r-2283 {
  background-position: -440px -2280px !important;
}

.r-2282 {
  background-position: -480px -2280px !important;
}

.r-2281 {
  background-position: -520px -2280px !important;
}

.r-2280 {
  background-position: -560px -2280px !important;
}

.r-2279 {
  background-position: -600px -2280px !important;
}

.r-2278 {
  background-position: -640px -2280px !important;
}

.r-2277 {
  background-position: -680px -2280px !important;
}

.r-2276 {
  background-position: -720px -2280px !important;
}

.r-2275 {
  background-position: -760px -2280px !important;
}

.r-2274 {
  background-position: -800px -2280px !important;
}

.r-2273 {
  background-position: -840px -2280px !important;
}

.r-2272 {
  background-position: -880px -2280px !important;
}

.r-2271 {
  background-position: -920px -2280px !important;
}

.r-2270 {
  background-position: -960px -2280px !important;
}

.r-2269 {
  background-position: 0 -2320px !important;
}

.r-2268 {
  background-position: -40px -2320px !important;
}

.r-2267 {
  background-position: -80px -2320px !important;
}

.r-2266 {
  background-position: -120px -2320px !important;
}

.r-2265 {
  background-position: -160px -2320px !important;
}

.r-2264 {
  background-position: -200px -2320px !important;
}

.r-2263 {
  background-position: -240px -2320px !important;
}

.r-2262 {
  background-position: -280px -2320px !important;
}

.r-2261 {
  background-position: -320px -2320px !important;
}

.r-2260 {
  background-position: -360px -2320px !important;
}

.r-2259 {
  background-position: -400px -2320px !important;
}

.r-2258 {
  background-position: -440px -2320px !important;
}

.r-2257 {
  background-position: -480px -2320px !important;
}

.r-2256 {
  background-position: -520px -2320px !important;
}

.r-2255 {
  background-position: -560px -2320px !important;
}

.r-2254 {
  background-position: -600px -2320px !important;
}

.r-2253 {
  background-position: -640px -2320px !important;
}

.r-2252 {
  background-position: -680px -2320px !important;
}

.r-2251 {
  background-position: -720px -2320px !important;
}

.r-2250 {
  background-position: -760px -2320px !important;
}

.r-2249 {
  background-position: -800px -2320px !important;
}

.r-2248 {
  background-position: -840px -2320px !important;
}

.r-2247 {
  background-position: -880px -2320px !important;
}

.r-2246 {
  background-position: -920px -2320px !important;
}

.r-2245 {
  background-position: -960px -2320px !important;
}

.r-2244 {
  background-position: 0 -2360px !important;
}

.r-2243 {
  background-position: -40px -2360px !important;
}

.r-2242 {
  background-position: -80px -2360px !important;
}

.r-2241 {
  background-position: -120px -2360px !important;
}

.r-2240 {
  background-position: -160px -2360px !important;
}

.r-2239 {
  background-position: -200px -2360px !important;
}

.r-2238 {
  background-position: -240px -2360px !important;
}

.r-2237 {
  background-position: -280px -2360px !important;
}

.r-2236 {
  background-position: -320px -2360px !important;
}

.r-2235 {
  background-position: -360px -2360px !important;
}

.r-2234 {
  background-position: -400px -2360px !important;
}

.r-2233 {
  background-position: -440px -2360px !important;
}

.r-2232 {
  background-position: -480px -2360px !important;
}

.r-2231 {
  background-position: -520px -2360px !important;
}

.r-2230 {
  background-position: -560px -2360px !important;
}

.r-2229 {
  background-position: -600px -2360px !important;
}

.r-2228 {
  background-position: -640px -2360px !important;
}

.r-2227 {
  background-position: -680px -2360px !important;
}

.r-2226 {
  background-position: -720px -2360px !important;
}

.r-2225 {
  background-position: -760px -2360px !important;
}

.r-2224 {
  background-position: -800px -2360px !important;
}

.r-2223 {
  background-position: -840px -2360px !important;
}

.r-2222 {
  background-position: -880px -2360px !important;
}

.r-2221 {
  background-position: -920px -2360px !important;
}

.r-2220 {
  background-position: -960px -2360px !important;
}

.r-2219 {
  background-position: 0 -2400px !important;
}

.r-2218 {
  background-position: -40px -2400px !important;
}

.r-2217 {
  background-position: -80px -2400px !important;
}

.r-2216 {
  background-position: -120px -2400px !important;
}

.r-2215 {
  background-position: -160px -2400px !important;
}

.r-2214 {
  background-position: -200px -2400px !important;
}

.r-2213 {
  background-position: -240px -2400px !important;
}

.r-2212 {
  background-position: -280px -2400px !important;
}

.r-2211 {
  background-position: -320px -2400px !important;
}

.r-2210 {
  background-position: -360px -2400px !important;
}

.r-2209 {
  background-position: -400px -2400px !important;
}

.r-2208 {
  background-position: -440px -2400px !important;
}

.r-2207 {
  background-position: -480px -2400px !important;
}

.r-2206 {
  background-position: -520px -2400px !important;
}

.r-2205 {
  background-position: -560px -2400px !important;
}

.r-2204 {
  background-position: -600px -2400px !important;
}

.r-2203 {
  background-position: -640px -2400px !important;
}

.r-2202 {
  background-position: -680px -2400px !important;
}

.r-2201 {
  background-position: -720px -2400px !important;
}

.r-2200 {
  background-position: -760px -2400px !important;
}

.r-2199 {
  background-position: -800px -2400px !important;
}

.r-2198 {
  background-position: -840px -2400px !important;
}

.r-2197 {
  background-position: -880px -2400px !important;
}

.r-2195 {
  background-position: -920px -2400px !important;
}

.r-2194 {
  background-position: -960px -2400px !important;
}

.r-2193 {
  background-position: 0 -2440px !important;
}

.r-2192 {
  background-position: -40px -2440px !important;
}

.r-2191 {
  background-position: -80px -2440px !important;
}

.r-2190 {
  background-position: -120px -2440px !important;
}

.r-2189 {
  background-position: -160px -2440px !important;
}

.r-2188 {
  background-position: -200px -2440px !important;
}

.r-2187 {
  background-position: -240px -2440px !important;
}

.r-2186 {
  background-position: -280px -2440px !important;
}

.r-2185 {
  background-position: -320px -2440px !important;
}

.r-2184 {
  background-position: -360px -2440px !important;
}

.r-2183 {
  background-position: -400px -2440px !important;
}

.r-2182 {
  background-position: -440px -2440px !important;
}

.r-2181 {
  background-position: -480px -2440px !important;
}

.r-2180 {
  background-position: -520px -2440px !important;
}

.r-2179 {
  background-position: -560px -2440px !important;
}

.r-2178 {
  background-position: -600px -2440px !important;
}

.r-2177 {
  background-position: -640px -2440px !important;
}

.r-2176 {
  background-position: -680px -2440px !important;
}

.r-2175 {
  background-position: -720px -2440px !important;
}

.r-2174 {
  background-position: -760px -2440px !important;
}

.r-2173 {
  background-position: -800px -2440px !important;
}

.r-2172 {
  background-position: -840px -2440px !important;
}

.r-2171 {
  background-position: -880px -2440px !important;
}

.r-2170 {
  background-position: -920px -2440px !important;
}

.r-2169 {
  background-position: -960px -2440px !important;
}

.r-2168 {
  background-position: 0 -2480px !important;
}

.r-2167 {
  background-position: -40px -2480px !important;
}

.r-2166 {
  background-position: -80px -2480px !important;
}

.r-2165 {
  background-position: -120px -2480px !important;
}

.r-2164 {
  background-position: -160px -2480px !important;
}

.r-2163 {
  background-position: -200px -2480px !important;
}

.r-2162 {
  background-position: -240px -2480px !important;
}

.r-2159 {
  background-position: -280px -2480px !important;
}

.r-2158 {
  background-position: -320px -2480px !important;
}

.r-2157 {
  background-position: -360px -2480px !important;
}

.r-2156 {
  background-position: -400px -2480px !important;
}

.r-2155 {
  background-position: -440px -2480px !important;
}

.r-2154 {
  background-position: -480px -2480px !important;
}

.r-2153 {
  background-position: -520px -2480px !important;
}

.r-2152 {
  background-position: -560px -2480px !important;
}

.r-2151 {
  background-position: -600px -2480px !important;
}

.r-2150 {
  background-position: -640px -2480px !important;
}

.r-2149 {
  background-position: -680px -2480px !important;
}

.r-2148 {
  background-position: -720px -2480px !important;
}

.r-2147 {
  background-position: -760px -2480px !important;
}

.r-2146 {
  background-position: -800px -2480px !important;
}

.r-2145 {
  background-position: -840px -2480px !important;
}

.r-2144 {
  background-position: -880px -2480px !important;
}

.r-2143 {
  background-position: -920px -2480px !important;
}

.r-2142 {
  background-position: -960px -2480px !important;
}

.r-2141 {
  background-position: 0 -2520px !important;
}

.r-2140 {
  background-position: -40px -2520px !important;
}

.r-2139 {
  background-position: -80px -2520px !important;
}

.r-2137 {
  background-position: -120px -2520px !important;
}

.r-2136 {
  background-position: -160px -2520px !important;
}

.r-2135 {
  background-position: -200px -2520px !important;
}

.r-2134 {
  background-position: -240px -2520px !important;
}

.r-2133 {
  background-position: -280px -2520px !important;
}

.r-2132 {
  background-position: -320px -2520px !important;
}

.r-2131 {
  background-position: -360px -2520px !important;
}

.r-2130 {
  background-position: -400px -2520px !important;
}

.r-2129 {
  background-position: -440px -2520px !important;
}

.r-2128 {
  background-position: -480px -2520px !important;
}

.r-2127 {
  background-position: -520px -2520px !important;
}

.r-2126 {
  background-position: -560px -2520px !important;
}

.r-2125 {
  background-position: -600px -2520px !important;
}

.r-2124 {
  background-position: -640px -2520px !important;
}

.r-2123 {
  background-position: -680px -2520px !important;
}

.r-2122 {
  background-position: -720px -2520px !important;
}

.r-2121 {
  background-position: -760px -2520px !important;
}

.r-2120 {
  background-position: -800px -2520px !important;
}

.r-2119 {
  background-position: -840px -2520px !important;
}

.r-2118 {
  background-position: -880px -2520px !important;
}

.r-2117 {
  background-position: -920px -2520px !important;
}

.r-2116 {
  background-position: -960px -2520px !important;
}

.r-2115 {
  background-position: 0 -2560px !important;
}

.r-2114 {
  background-position: -40px -2560px !important;
}

.r-2113 {
  background-position: -80px -2560px !important;
}

.r-2112 {
  background-position: -120px -2560px !important;
}

.r-2111 {
  background-position: -160px -2560px !important;
}

.r-2110 {
  background-position: -200px -2560px !important;
}

.r-2109 {
  background-position: -240px -2560px !important;
}

.r-2108 {
  background-position: -280px -2560px !important;
}

.r-2105 {
  background-position: -320px -2560px !important;
}

.r-2104 {
  background-position: -360px -2560px !important;
}

.r-2103 {
  background-position: -400px -2560px !important;
}

.r-2102 {
  background-position: -440px -2560px !important;
}

.r-2101 {
  background-position: -480px -2560px !important;
}

.r-2100 {
  background-position: -520px -2560px !important;
}

.r-2099 {
  background-position: -560px -2560px !important;
}

.r-2098 {
  background-position: -600px -2560px !important;
}

.r-2097 {
  background-position: -640px -2560px !important;
}

.r-2096 {
  background-position: -680px -2560px !important;
}

.r-2095 {
  background-position: -720px -2560px !important;
}

.r-2093 {
  background-position: -760px -2560px !important;
}

.r-2092 {
  background-position: -800px -2560px !important;
}

.r-2091 {
  background-position: -840px -2560px !important;
}

.r-2090 {
  background-position: -880px -2560px !important;
}

.r-2089 {
  background-position: -920px -2560px !important;
}

.r-2088 {
  background-position: -960px -2560px !important;
}

.r-2087 {
  background-position: 0 -2600px !important;
}

.r-2085 {
  background-position: -40px -2600px !important;
}

.r-2084 {
  background-position: -80px -2600px !important;
}

.r-2083 {
  background-position: -120px -2600px !important;
}

.r-2082 {
  background-position: -160px -2600px !important;
}

.r-2081 {
  background-position: -200px -2600px !important;
}

.r-2080 {
  background-position: -240px -2600px !important;
}

.r-2079 {
  background-position: -280px -2600px !important;
}

.r-2078 {
  background-position: -320px -2600px !important;
}

.r-2077 {
  background-position: -360px -2600px !important;
}

.r-2076 {
  background-position: -400px -2600px !important;
}

.r-2075 {
  background-position: -440px -2600px !important;
}

.r-2074 {
  background-position: -480px -2600px !important;
}

.r-2073 {
  background-position: -520px -2600px !important;
}

.r-2072 {
  background-position: -560px -2600px !important;
}

.r-2071 {
  background-position: -600px -2600px !important;
}

.r-2070 {
  background-position: -640px -2600px !important;
}

.r-2069 {
  background-position: -680px -2600px !important;
}

.r-2068 {
  background-position: -720px -2600px !important;
}

.r-2067 {
  background-position: -760px -2600px !important;
}

.r-2066 {
  background-position: -800px -2600px !important;
}

.r-2065 {
  background-position: -840px -2600px !important;
}

.r-2063 {
  background-position: -880px -2600px !important;
}

.r-2062 {
  background-position: -920px -2600px !important;
}

.r-2061 {
  background-position: -960px -2600px !important;
}

.r-2060 {
  background-position: 0 -2640px !important;
}

.r-2059 {
  background-position: -40px -2640px !important;
}

.r-2058 {
  background-position: -80px -2640px !important;
}

.r-2057 {
  background-position: -120px -2640px !important;
}

.r-2056 {
  background-position: -160px -2640px !important;
}

.r-2055 {
  background-position: -200px -2640px !important;
}

.r-2054 {
  background-position: -240px -2640px !important;
}

.r-2053 {
  background-position: -280px -2640px !important;
}

.r-2052 {
  background-position: -320px -2640px !important;
}

.r-2051 {
  background-position: -360px -2640px !important;
}

.r-2050 {
  background-position: -400px -2640px !important;
}

.r-2049 {
  background-position: -440px -2640px !important;
}

.r-2048 {
  background-position: -480px -2640px !important;
}

.r-2047 {
  background-position: -520px -2640px !important;
}

.r-2046 {
  background-position: -560px -2640px !important;
}

.r-2045 {
  background-position: -600px -2640px !important;
}

.r-2044 {
  background-position: -640px -2640px !important;
}

.r-2043 {
  background-position: -680px -2640px !important;
}

.r-2042 {
  background-position: -720px -2640px !important;
}

.r-2040 {
  background-position: -760px -2640px !important;
}

.r-2039 {
  background-position: -800px -2640px !important;
}

.r-2038 {
  background-position: -840px -2640px !important;
}

.r-2037 {
  background-position: -880px -2640px !important;
}

.r-2036 {
  background-position: -920px -2640px !important;
}

.r-2035 {
  background-position: -960px -2640px !important;
}

.r-2034 {
  background-position: 0 -2680px !important;
}

.r-2033 {
  background-position: -40px -2680px !important;
}

.r-2032 {
  background-position: -80px -2680px !important;
}

.r-2031 {
  background-position: -120px -2680px !important;
}

.r-2030 {
  background-position: -160px -2680px !important;
}

.r-2029 {
  background-position: -200px -2680px !important;
}

.r-2028 {
  background-position: -240px -2680px !important;
}

.r-2025 {
  background-position: -280px -2680px !important;
}

.r-2024 {
  background-position: -320px -2680px !important;
}

.r-2023 {
  background-position: -360px -2680px !important;
}

.r-2022 {
  background-position: -400px -2680px !important;
}

.r-2021 {
  background-position: -440px -2680px !important;
}

.r-2020 {
  background-position: -480px -2680px !important;
}

.r-2019 {
  background-position: -520px -2680px !important;
}

.r-2018 {
  background-position: -560px -2680px !important;
}

.r-2017 {
  background-position: -600px -2680px !important;
}

.r-2014 {
  background-position: -640px -2680px !important;
}

.r-2013 {
  background-position: -680px -2680px !important;
}

.r-2012 {
  background-position: -720px -2680px !important;
}

.r-2011 {
  background-position: -760px -2680px !important;
}

.r-2010 {
  background-position: -800px -2680px !important;
}

.r-2009 {
  background-position: -840px -2680px !important;
}

.r-2008 {
  background-position: -880px -2680px !important;
}

.r-2007 {
  background-position: -920px -2680px !important;
}

.r-2006 {
  background-position: -960px -2680px !important;
}

.r-2005 {
  background-position: 0 -2720px !important;
}

.r-2004 {
  background-position: -40px -2720px !important;
}

.r-2003 {
  background-position: -80px -2720px !important;
}

.r-2002 {
  background-position: -120px -2720px !important;
}

.r-2001 {
  background-position: -160px -2720px !important;
}

.r-2000 {
  background-position: -200px -2720px !important;
}

.r-1999 {
  background-position: -240px -2720px !important;
}

.r-1998 {
  background-position: -280px -2720px !important;
}

.r-1997 {
  background-position: -320px -2720px !important;
}

.r-1996 {
  background-position: -360px -2720px !important;
}

.r-1995 {
  background-position: -400px -2720px !important;
}

.r-1994 {
  background-position: -440px -2720px !important;
}

.r-1993 {
  background-position: -480px -2720px !important;
}

.r-1992 {
  background-position: -520px -2720px !important;
}

.r-1991 {
  background-position: -560px -2720px !important;
}

.r-1990 {
  background-position: -600px -2720px !important;
}

.r-1989 {
  background-position: -640px -2720px !important;
}

.r-1988 {
  background-position: -680px -2720px !important;
}

.r-1987 {
  background-position: -720px -2720px !important;
}

.r-1986 {
  background-position: -760px -2720px !important;
}

.r-1985 {
  background-position: -800px -2720px !important;
}

.r-1984 {
  background-position: -840px -2720px !important;
}

.r-1983 {
  background-position: -880px -2720px !important;
}

.r-1982 {
  background-position: -920px -2720px !important;
}

.r-1981 {
  background-position: -960px -2720px !important;
}

.r-1980 {
  background-position: 0 -2760px !important;
}

.r-1979 {
  background-position: -40px -2760px !important;
}

.r-1978 {
  background-position: -80px -2760px !important;
}

.r-1977 {
  background-position: -120px -2760px !important;
}

.r-1976 {
  background-position: -160px -2760px !important;
}

.r-1975 {
  background-position: -200px -2760px !important;
}

.r-1974 {
  background-position: -240px -2760px !important;
}

.r-1972 {
  background-position: -280px -2760px !important;
}

.r-1971 {
  background-position: -320px -2760px !important;
}

.r-1970 {
  background-position: -360px -2760px !important;
}

.r-1969 {
  background-position: -400px -2760px !important;
}

.r-1968 {
  background-position: -440px -2760px !important;
}

.r-1967 {
  background-position: -480px -2760px !important;
}

.r-1966 {
  background-position: -520px -2760px !important;
}

.r-1965 {
  background-position: -560px -2760px !important;
}

.r-1964 {
  background-position: -600px -2760px !important;
}

.r-1963 {
  background-position: -640px -2760px !important;
}

.r-1962 {
  background-position: -680px -2760px !important;
}

.r-1961 {
  background-position: -720px -2760px !important;
}

.r-1960 {
  background-position: -760px -2760px !important;
}

.r-1959 {
  background-position: -800px -2760px !important;
}

.r-1958 {
  background-position: -840px -2760px !important;
}

.r-1957 {
  background-position: -880px -2760px !important;
}

.r-1956 {
  background-position: -920px -2760px !important;
}

.r-1955 {
  background-position: -960px -2760px !important;
}

.r-1954 {
  background-position: 0 -2800px !important;
}

.r-1953 {
  background-position: -40px -2800px !important;
}

.r-1951 {
  background-position: -80px -2800px !important;
}

.r-1950 {
  background-position: -120px -2800px !important;
}

.r-1949 {
  background-position: -160px -2800px !important;
}

.r-1948 {
  background-position: -200px -2800px !important;
}

.r-1946 {
  background-position: -240px -2800px !important;
}

.r-1945 {
  background-position: -280px -2800px !important;
}

.r-1944 {
  background-position: -320px -2800px !important;
}

.r-1943 {
  background-position: -360px -2800px !important;
}

.r-1942 {
  background-position: -400px -2800px !important;
}

.r-1941 {
  background-position: -440px -2800px !important;
}

.r-1940 {
  background-position: -480px -2800px !important;
}

.r-1939 {
  background-position: -520px -2800px !important;
}

.r-1938 {
  background-position: -560px -2800px !important;
}

.r-1936 {
  background-position: -600px -2800px !important;
}

.r-1935 {
  background-position: -640px -2800px !important;
}

.r-1934 {
  background-position: -680px -2800px !important;
}

.r-1933 {
  background-position: -720px -2800px !important;
}

.r-1932 {
  background-position: -760px -2800px !important;
}

.r-1931 {
  background-position: -800px -2800px !important;
}

.r-1930 {
  background-position: -840px -2800px !important;
}

.r-1929 {
  background-position: -880px -2800px !important;
}

.r-1928 {
  background-position: -920px -2800px !important;
}

.r-1927 {
  background-position: -960px -2800px !important;
}

.r-1926 {
  background-position: 0 -2840px !important;
}

.r-1924 {
  background-position: -40px -2840px !important;
}

.r-1923 {
  background-position: -80px -2840px !important;
}

.r-1922 {
  background-position: -120px -2840px !important;
}

.r-1921 {
  background-position: -160px -2840px !important;
}

.r-1920 {
  background-position: -200px -2840px !important;
}

.r-1918 {
  background-position: -240px -2840px !important;
}

.r-1917 {
  background-position: -280px -2840px !important;
}

.r-1916 {
  background-position: -320px -2840px !important;
}

.r-1915 {
  background-position: -360px -2840px !important;
}

.r-1914 {
  background-position: -400px -2840px !important;
}

.r-1913 {
  background-position: -440px -2840px !important;
}

.r-1912 {
  background-position: -480px -2840px !important;
}

.r-1911 {
  background-position: -520px -2840px !important;
}

.r-1910 {
  background-position: -560px -2840px !important;
}

.r-1909 {
  background-position: -600px -2840px !important;
}

.r-1908 {
  background-position: -640px -2840px !important;
}

.r-1907 {
  background-position: -680px -2840px !important;
}

.r-1906 {
  background-position: -720px -2840px !important;
}

.r-1905 {
  background-position: -760px -2840px !important;
}

.r-1904 {
  background-position: -800px -2840px !important;
}

.r-1903 {
  background-position: -840px -2840px !important;
}

.r-1902 {
  background-position: -880px -2840px !important;
}

.r-1901 {
  background-position: -920px -2840px !important;
}

.r-1900 {
  background-position: -960px -2840px !important;
}

.r-1899 {
  background-position: 0 -2880px !important;
}

.r-1898 {
  background-position: -40px -2880px !important;
}

.r-1896 {
  background-position: -80px -2880px !important;
}

.r-1895 {
  background-position: -120px -2880px !important;
}

.r-1894 {
  background-position: -160px -2880px !important;
}

.r-1893 {
  background-position: -200px -2880px !important;
}

.r-1892 {
  background-position: -240px -2880px !important;
}

.r-1891 {
  background-position: -280px -2880px !important;
}

.r-1890 {
  background-position: -320px -2880px !important;
}

.r-1889 {
  background-position: -360px -2880px !important;
}

.r-1888 {
  background-position: -400px -2880px !important;
}

.r-1887 {
  background-position: -440px -2880px !important;
}

.r-1885 {
  background-position: -480px -2880px !important;
}

.r-1884 {
  background-position: -520px -2880px !important;
}

.r-1883 {
  background-position: -560px -2880px !important;
}

.r-1882 {
  background-position: -600px -2880px !important;
}

.r-1881 {
  background-position: -640px -2880px !important;
}

.r-1878 {
  background-position: -680px -2880px !important;
}

.r-1877 {
  background-position: -720px -2880px !important;
}

.r-1876 {
  background-position: -760px -2880px !important;
}

.r-1874 {
  background-position: -800px -2880px !important;
}

.r-1873 {
  background-position: -840px -2880px !important;
}

.r-1872 {
  background-position: -880px -2880px !important;
}

.r-1871 {
  background-position: -920px -2880px !important;
}

.r-1870 {
  background-position: -960px -2880px !important;
}

.r-1869 {
  background-position: 0 -2920px !important;
}

.r-1868 {
  background-position: -40px -2920px !important;
}

.r-1867 {
  background-position: -80px -2920px !important;
}

.r-1866 {
  background-position: -120px -2920px !important;
}

.r-1865 {
  background-position: -160px -2920px !important;
}

.r-1864 {
  background-position: -200px -2920px !important;
}

.r-1863 {
  background-position: -240px -2920px !important;
}

.r-1862 {
  background-position: -280px -2920px !important;
}

.r-1861 {
  background-position: -320px -2920px !important;
}

.r-1860 {
  background-position: -360px -2920px !important;
}

.r-1858 {
  background-position: -400px -2920px !important;
}

.r-1857 {
  background-position: -440px -2920px !important;
}

.r-1856 {
  background-position: -480px -2920px !important;
}

.r-1855 {
  background-position: -520px -2920px !important;
}

.r-1854 {
  background-position: -560px -2920px !important;
}

.r-1853 {
  background-position: -600px -2920px !important;
}

.r-1852 {
  background-position: -640px -2920px !important;
}

.r-1851 {
  background-position: -680px -2920px !important;
}

.r-1850 {
  background-position: -720px -2920px !important;
}

.r-1849 {
  background-position: -760px -2920px !important;
}

.r-1848 {
  background-position: -800px -2920px !important;
}

.r-1847 {
  background-position: -840px -2920px !important;
}

.r-1846 {
  background-position: -880px -2920px !important;
}

.r-1845 {
  background-position: -920px -2920px !important;
}

.r-1844 {
  background-position: -960px -2920px !important;
}

.r-1843 {
  background-position: 0 -2960px !important;
}

.r-1842 {
  background-position: -40px -2960px !important;
}

.r-1841 {
  background-position: -80px -2960px !important;
}

.r-1840 {
  background-position: -120px -2960px !important;
}

.r-1839 {
  background-position: -160px -2960px !important;
}

.r-1838 {
  background-position: -200px -2960px !important;
}

.r-1837 {
  background-position: -240px -2960px !important;
}

.r-1836 {
  background-position: -280px -2960px !important;
}

.r-1835 {
  background-position: -320px -2960px !important;
}

.r-1834 {
  background-position: -360px -2960px !important;
}

.r-1833 {
  background-position: -400px -2960px !important;
}

.r-1832 {
  background-position: -440px -2960px !important;
}

.r-1831 {
  background-position: -480px -2960px !important;
}

.r-1830 {
  background-position: -520px -2960px !important;
}

.r-1829 {
  background-position: -560px -2960px !important;
}

.r-1827 {
  background-position: -600px -2960px !important;
}

.r-1826 {
  background-position: -640px -2960px !important;
}

.r-1825 {
  background-position: -680px -2960px !important;
}

.r-1824 {
  background-position: -720px -2960px !important;
}

.r-1823 {
  background-position: -760px -2960px !important;
}

.r-1822 {
  background-position: -800px -2960px !important;
}

.r-1821 {
  background-position: -840px -2960px !important;
}

.r-1820 {
  background-position: -880px -2960px !important;
}

.r-1819 {
  background-position: -920px -2960px !important;
}

.r-1818 {
  background-position: -960px -2960px !important;
}

.r-1817 {
  background-position: 0 -3000px !important;
}

.r-1816 {
  background-position: -40px -3000px !important;
}

.r-1815 {
  background-position: -80px -3000px !important;
}

.r-1814 {
  background-position: -120px -3000px !important;
}

.r-1810 {
  background-position: -160px -3000px !important;
}

.r-1809 {
  background-position: -200px -3000px !important;
}

.r-1808 {
  background-position: -240px -3000px !important;
}

.r-1806 {
  background-position: -280px -3000px !important;
}

.r-1805 {
  background-position: -320px -3000px !important;
}

.r-1804 {
  background-position: -360px -3000px !important;
}

.r-1803 {
  background-position: -400px -3000px !important;
}

.r-1802 {
  background-position: -440px -3000px !important;
}

.r-1801 {
  background-position: -480px -3000px !important;
}

.r-1800 {
  background-position: -520px -3000px !important;
}

.r-1799 {
  background-position: -560px -3000px !important;
}

.r-1798 {
  background-position: -600px -3000px !important;
}

.r-1795 {
  background-position: -640px -3000px !important;
}

.r-1794 {
  background-position: -680px -3000px !important;
}

.r-1793 {
  background-position: -720px -3000px !important;
}

.r-1792 {
  background-position: -760px -3000px !important;
}

.r-1790 {
  background-position: -800px -3000px !important;
}

.r-1789 {
  background-position: -840px -3000px !important;
}

.r-1788 {
  background-position: -880px -3000px !important;
}

.r-1787 {
  background-position: -920px -3000px !important;
}

.r-1786 {
  background-position: -960px -3000px !important;
}

.r-1785 {
  background-position: 0 -3040px !important;
}

.r-1784 {
  background-position: -40px -3040px !important;
}

.r-1783 {
  background-position: -80px -3040px !important;
}

.r-1782 {
  background-position: -120px -3040px !important;
}

.r-1781 {
  background-position: -160px -3040px !important;
}

.r-1780 {
  background-position: -200px -3040px !important;
}

.r-1779 {
  background-position: -240px -3040px !important;
}

.r-1778 {
  background-position: -280px -3040px !important;
}

.r-1777 {
  background-position: -320px -3040px !important;
}

.r-1776 {
  background-position: -360px -3040px !important;
}

.r-1775 {
  background-position: -400px -3040px !important;
}

.r-1774 {
  background-position: -440px -3040px !important;
}

.r-1773 {
  background-position: -480px -3040px !important;
}

.r-1772 {
  background-position: -520px -3040px !important;
}

.r-1771 {
  background-position: -560px -3040px !important;
}

.r-1770 {
  background-position: -600px -3040px !important;
}

.r-1769 {
  background-position: -640px -3040px !important;
}

.r-1768 {
  background-position: -680px -3040px !important;
}

.r-1767 {
  background-position: -720px -3040px !important;
}

.r-1766 {
  background-position: -760px -3040px !important;
}

.r-1765 {
  background-position: -800px -3040px !important;
}

.r-1764 {
  background-position: -840px -3040px !important;
}

.r-1763 {
  background-position: -880px -3040px !important;
}

.r-1761 {
  background-position: -920px -3040px !important;
}

.r-1760 {
  background-position: -960px -3040px !important;
}

.r-1759 {
  background-position: 0 -3080px !important;
}

.r-1758 {
  background-position: -40px -3080px !important;
}

.r-1757 {
  background-position: -80px -3080px !important;
}

.r-1756 {
  background-position: -120px -3080px !important;
}

.r-1755 {
  background-position: -160px -3080px !important;
}

.r-1754 {
  background-position: -200px -3080px !important;
}

.r-1753 {
  background-position: -240px -3080px !important;
}

.r-1752 {
  background-position: -280px -3080px !important;
}

.r-1751 {
  background-position: -320px -3080px !important;
}

.r-1750 {
  background-position: -360px -3080px !important;
}

.r-1749 {
  background-position: -400px -3080px !important;
}

.r-1747 {
  background-position: -440px -3080px !important;
}

.r-1746 {
  background-position: -480px -3080px !important;
}

.r-1745 {
  background-position: -520px -3080px !important;
}

.r-1744 {
  background-position: -560px -3080px !important;
}

.r-1743 {
  background-position: -600px -3080px !important;
}

.r-1742 {
  background-position: -640px -3080px !important;
}

.r-1741 {
  background-position: -680px -3080px !important;
}

.r-1740 {
  background-position: -720px -3080px !important;
}

.r-1739 {
  background-position: -760px -3080px !important;
}

.r-1738 {
  background-position: -800px -3080px !important;
}

.r-1737 {
  background-position: -840px -3080px !important;
}

.r-1736 {
  background-position: -880px -3080px !important;
}

.r-1735 {
  background-position: -920px -3080px !important;
}

.r-1734 {
  background-position: -960px -3080px !important;
}

.r-1733 {
  background-position: 0 -3120px !important;
}

.r-1732 {
  background-position: -40px -3120px !important;
}

.r-1731 {
  background-position: -80px -3120px !important;
}

.r-1730 {
  background-position: -120px -3120px !important;
}

.r-1729 {
  background-position: -160px -3120px !important;
}

.r-1728 {
  background-position: -200px -3120px !important;
}

.r-1727 {
  background-position: -240px -3120px !important;
}

.r-1726 {
  background-position: -280px -3120px !important;
}

.r-1725 {
  background-position: -320px -3120px !important;
}

.r-1724 {
  background-position: -360px -3120px !important;
}

.r-1723 {
  background-position: -400px -3120px !important;
}

.r-1722 {
  background-position: -440px -3120px !important;
}

.r-1721 {
  background-position: -480px -3120px !important;
}

.r-1720 {
  background-position: -520px -3120px !important;
}

.r-1719 {
  background-position: -560px -3120px !important;
}

.r-1718 {
  background-position: -600px -3120px !important;
}

.r-1717 {
  background-position: -640px -3120px !important;
}

.r-1716 {
  background-position: -680px -3120px !important;
}

.r-1715 {
  background-position: -720px -3120px !important;
}

.r-1714 {
  background-position: -760px -3120px !important;
}

.r-1713 {
  background-position: -800px -3120px !important;
}

.r-1712 {
  background-position: -840px -3120px !important;
}

.r-1711 {
  background-position: -880px -3120px !important;
}

.r-1710 {
  background-position: -920px -3120px !important;
}

.r-1709 {
  background-position: -960px -3120px !important;
}

.r-1708 {
  background-position: 0 -3160px !important;
}

.r-1707 {
  background-position: -40px -3160px !important;
}

.r-1706 {
  background-position: -80px -3160px !important;
}

.r-1703 {
  background-position: -120px -3160px !important;
}

.r-1702 {
  background-position: -160px -3160px !important;
}

.r-1701 {
  background-position: -200px -3160px !important;
}

.r-1700 {
  background-position: -240px -3160px !important;
}

.r-1699 {
  background-position: -280px -3160px !important;
}

.r-1698 {
  background-position: -320px -3160px !important;
}

.r-1696 {
  background-position: -360px -3160px !important;
}

.r-1695 {
  background-position: -400px -3160px !important;
}

.r-1694 {
  background-position: -440px -3160px !important;
}

.r-1693 {
  background-position: -480px -3160px !important;
}

.r-1692 {
  background-position: -520px -3160px !important;
}

.r-1691 {
  background-position: -560px -3160px !important;
}

.r-1686 {
  background-position: -600px -3160px !important;
}

.r-1685 {
  background-position: -640px -3160px !important;
}

.r-1684 {
  background-position: -680px -3160px !important;
}

.r-1683 {
  background-position: -720px -3160px !important;
}

.r-1682 {
  background-position: -760px -3160px !important;
}

.r-1681 {
  background-position: -800px -3160px !important;
}

.r-1680 {
  background-position: -840px -3160px !important;
}

.r-1679 {
  background-position: -880px -3160px !important;
}

.r-1678 {
  background-position: -920px -3160px !important;
}

.r-1677 {
  background-position: -960px -3160px !important;
}

.r-1676 {
  background-position: 0 -3200px !important;
}

.r-1675 {
  background-position: -40px -3200px !important;
}

.r-1674 {
  background-position: -80px -3200px !important;
}

.r-1673 {
  background-position: -120px -3200px !important;
}

.r-1672 {
  background-position: -160px -3200px !important;
}

.r-1671 {
  background-position: -200px -3200px !important;
}

.r-1670 {
  background-position: -240px -3200px !important;
}

.r-1669 {
  background-position: -280px -3200px !important;
}

.r-1668 {
  background-position: -320px -3200px !important;
}

.r-1667 {
  background-position: -360px -3200px !important;
}

.r-1666 {
  background-position: -400px -3200px !important;
}

.r-1665 {
  background-position: -440px -3200px !important;
}

.r-1664 {
  background-position: -480px -3200px !important;
}

.r-1663 {
  background-position: -520px -3200px !important;
}

.r-1662 {
  background-position: -560px -3200px !important;
}

.r-1661 {
  background-position: -600px -3200px !important;
}

.r-1660 {
  background-position: -640px -3200px !important;
}

.r-1659 {
  background-position: -680px -3200px !important;
}

.r-1658 {
  background-position: -720px -3200px !important;
}

.r-1657 {
  background-position: -760px -3200px !important;
}

.r-1656 {
  background-position: -800px -3200px !important;
}

.r-1655 {
  background-position: -840px -3200px !important;
}

.r-1654 {
  background-position: -880px -3200px !important;
}

.r-1653 {
  background-position: -920px -3200px !important;
}

.r-1652 {
  background-position: -960px -3200px !important;
}

.r-1651 {
  background-position: 0 -3240px !important;
}

.r-1650 {
  background-position: -40px -3240px !important;
}

.r-1649 {
  background-position: -80px -3240px !important;
}

.r-1648 {
  background-position: -120px -3240px !important;
}

.r-1647 {
  background-position: -160px -3240px !important;
}

.r-1644 {
  background-position: -200px -3240px !important;
}

.r-1643 {
  background-position: -240px -3240px !important;
}

.r-1642 {
  background-position: -280px -3240px !important;
}

.r-1641 {
  background-position: -320px -3240px !important;
}

.r-1640 {
  background-position: -360px -3240px !important;
}

.r-1639 {
  background-position: -400px -3240px !important;
}

.r-1638 {
  background-position: -440px -3240px !important;
}

.r-1637 {
  background-position: -480px -3240px !important;
}

.r-1636 {
  background-position: -520px -3240px !important;
}

.r-1635 {
  background-position: -560px -3240px !important;
}

.r-1634 {
  background-position: -600px -3240px !important;
}

.r-1633 {
  background-position: -640px -3240px !important;
}

.r-1632 {
  background-position: -680px -3240px !important;
}

.r-1631 {
  background-position: -720px -3240px !important;
}

.r-1630 {
  background-position: -760px -3240px !important;
}

.r-1629 {
  background-position: -800px -3240px !important;
}

.r-1628 {
  background-position: -840px -3240px !important;
}

.r-1627 {
  background-position: -880px -3240px !important;
}

.r-1626 {
  background-position: -920px -3240px !important;
}

.r-1625 {
  background-position: -960px -3240px !important;
}

.r-1624 {
  background-position: 0 -3280px !important;
}

.r-1623 {
  background-position: -40px -3280px !important;
}

.r-1622 {
  background-position: -80px -3280px !important;
}

.r-1621 {
  background-position: -120px -3280px !important;
}

.r-1620 {
  background-position: -160px -3280px !important;
}

.r-1619 {
  background-position: -200px -3280px !important;
}

.r-1618 {
  background-position: -240px -3280px !important;
}

.r-1617 {
  background-position: -280px -3280px !important;
}

.r-1616 {
  background-position: -320px -3280px !important;
}

.r-1615 {
  background-position: -360px -3280px !important;
}

.r-1614 {
  background-position: -400px -3280px !important;
}

.r-1613 {
  background-position: -440px -3280px !important;
}

.r-1612 {
  background-position: -480px -3280px !important;
}

.r-1607 {
  background-position: -520px -3280px !important;
}

.r-1606 {
  background-position: -560px -3280px !important;
}

.r-1605 {
  background-position: -600px -3280px !important;
}

.r-1604 {
  background-position: -640px -3280px !important;
}

.r-1603 {
  background-position: -680px -3280px !important;
}

.r-1602 {
  background-position: -720px -3280px !important;
}

.r-1601 {
  background-position: -760px -3280px !important;
}

.r-1600 {
  background-position: -800px -3280px !important;
}

.r-1599 {
  background-position: -840px -3280px !important;
}

.r-1598 {
  background-position: -880px -3280px !important;
}

.r-1597 {
  background-position: -920px -3280px !important;
}

.r-1596 {
  background-position: -960px -3280px !important;
}

.r-1595 {
  background-position: 0 -3320px !important;
}

.r-1594 {
  background-position: -40px -3320px !important;
}

.r-1593 {
  background-position: -80px -3320px !important;
}

.r-1592 {
  background-position: -120px -3320px !important;
}

.r-1591 {
  background-position: -160px -3320px !important;
}

.r-1590 {
  background-position: -200px -3320px !important;
}

.r-1589 {
  background-position: -240px -3320px !important;
}

.r-1588 {
  background-position: -280px -3320px !important;
}

.r-1573 {
  background-position: -320px -3320px !important;
}

.r-1572 {
  background-position: -360px -3320px !important;
}

.r-1571 {
  background-position: -400px -3320px !important;
}

.r-1570 {
  background-position: -440px -3320px !important;
}

.r-1569 {
  background-position: -480px -3320px !important;
}

.r-1568 {
  background-position: -520px -3320px !important;
}

.r-1567 {
  background-position: -560px -3320px !important;
}

.r-1566 {
  background-position: -600px -3320px !important;
}

.r-1554 {
  background-position: -640px -3320px !important;
}

.r-1553 {
  background-position: -680px -3320px !important;
}

.r-1552 {
  background-position: -720px -3320px !important;
}

.r-1551 {
  background-position: -760px -3320px !important;
}

.r-1550 {
  background-position: -800px -3320px !important;
}

.r-1549 {
  background-position: -840px -3320px !important;
}

.r-1548 {
  background-position: -880px -3320px !important;
}

.r-1547 {
  background-position: -920px -3320px !important;
}

.r-1546 {
  background-position: -960px -3320px !important;
}

.r-1545 {
  background-position: 0 -3360px !important;
}

.r-1544 {
  background-position: -40px -3360px !important;
}

.r-1543 {
  background-position: -80px -3360px !important;
}

.r-1542 {
  background-position: -120px -3360px !important;
}

.r-1541 {
  background-position: -160px -3360px !important;
}

.r-1540 {
  background-position: -200px -3360px !important;
}

.r-1539 {
  background-position: -240px -3360px !important;
}

.r-1538 {
  background-position: -280px -3360px !important;
}

.r-1537 {
  background-position: -320px -3360px !important;
}

.r-1533 {
  background-position: -360px -3360px !important;
}

.r-1532 {
  background-position: -400px -3360px !important;
}

.r-1531 {
  background-position: -440px -3360px !important;
}

.r-1530 {
  background-position: -480px -3360px !important;
}

.r-1529 {
  background-position: -520px -3360px !important;
}

.r-1528 {
  background-position: -560px -3360px !important;
}

.r-1527 {
  background-position: -600px -3360px !important;
}

.r-1522 {
  background-position: -640px -3360px !important;
}

.r-1521 {
  background-position: -680px -3360px !important;
}

.r-1520 {
  background-position: -720px -3360px !important;
}

.r-1519 {
  background-position: -760px -3360px !important;
}

.r-1518 {
  background-position: -800px -3360px !important;
}

.r-1517 {
  background-position: -840px -3360px !important;
}

.r-1516 {
  background-position: -880px -3360px !important;
}

.r-1515 {
  background-position: -920px -3360px !important;
}

.r-1514 {
  background-position: -960px -3360px !important;
}

.r-1510 {
  background-position: 0 -3400px !important;
}

.r-1509 {
  background-position: -40px -3400px !important;
}

.r-1508 {
  background-position: -80px -3400px !important;
}

.r-1507 {
  background-position: -120px -3400px !important;
}

.r-1506 {
  background-position: -160px -3400px !important;
}

.r-1505 {
  background-position: -200px -3400px !important;
}

.r-1504 {
  background-position: -240px -3400px !important;
}

.r-1498 {
  background-position: -280px -3400px !important;
}

.r-1497 {
  background-position: -320px -3400px !important;
}

.r-1496 {
  background-position: -360px -3400px !important;
}

.r-1495 {
  background-position: -400px -3400px !important;
}

.r-1494 {
  background-position: -440px -3400px !important;
}

.r-1493 {
  background-position: -480px -3400px !important;
}

.r-1492 {
  background-position: -520px -3400px !important;
}

.r-1491 {
  background-position: -560px -3400px !important;
}

.r-1488 {
  background-position: -600px -3400px !important;
}

.r-1487 {
  background-position: -640px -3400px !important;
}

.r-1486 {
  background-position: -680px -3400px !important;
}

.r-1485 {
  background-position: -720px -3400px !important;
}

.r-1484 {
  background-position: -760px -3400px !important;
}

.r-1483 {
  background-position: -800px -3400px !important;
}

.r-1482 {
  background-position: -840px -3400px !important;
}

.r-1475 {
  background-position: -880px -3400px !important;
}

.r-1474 {
  background-position: -920px -3400px !important;
}

.r-1473 {
  background-position: -960px -3400px !important;
}

.r-1472 {
  background-position: 0 -3440px !important;
}

.r-1471 {
  background-position: -40px -3440px !important;
}

.r-1470 {
  background-position: -80px -3440px !important;
}

.r-1469 {
  background-position: -120px -3440px !important;
}

.r-1468 {
  background-position: -160px -3440px !important;
}

.r-1467 {
  background-position: -200px -3440px !important;
}

.r-1466 {
  background-position: -240px -3440px !important;
}

.r-1465 {
  background-position: -280px -3440px !important;
}

.r-1464 {
  background-position: -320px -3440px !important;
}

.r-1463 {
  background-position: -360px -3440px !important;
}

.r-1462 {
  background-position: -400px -3440px !important;
}

.r-1460 {
  background-position: -440px -3440px !important;
}

.r-1459 {
  background-position: -480px -3440px !important;
}

.r-1458 {
  background-position: -520px -3440px !important;
}

.r-1457 {
  background-position: -560px -3440px !important;
}

.r-1456 {
  background-position: -600px -3440px !important;
}

.r-1455 {
  background-position: -640px -3440px !important;
}

.r-1454 {
  background-position: -680px -3440px !important;
}

.r-1453 {
  background-position: -720px -3440px !important;
}

.r-1452 {
  background-position: -760px -3440px !important;
}

.r-1451 {
  background-position: -800px -3440px !important;
}

.r-1450 {
  background-position: -840px -3440px !important;
}

.r-1449 {
  background-position: -880px -3440px !important;
}

.r-1443 {
  background-position: -920px -3440px !important;
}

.r-1442 {
  background-position: -960px -3440px !important;
}

.r-1441 {
  background-position: 0 -3480px !important;
}

.r-1440 {
  background-position: -40px -3480px !important;
}

.r-1439 {
  background-position: -80px -3480px !important;
}

.r-1438 {
  background-position: -120px -3480px !important;
}

.r-1437 {
  background-position: -160px -3480px !important;
}

.r-1436 {
  background-position: -200px -3480px !important;
}

.r-1435 {
  background-position: -240px -3480px !important;
}

.r-1434 {
  background-position: -280px -3480px !important;
}

.r-1433 {
  background-position: -320px -3480px !important;
}

.r-1432 {
  background-position: -360px -3480px !important;
}

.r-1431 {
  background-position: -400px -3480px !important;
}

.r-1430 {
  background-position: -440px -3480px !important;
}

.r-1429 {
  background-position: -480px -3480px !important;
}

.r-1428 {
  background-position: -520px -3480px !important;
}

.r-1427 {
  background-position: -560px -3480px !important;
}

.r-1426 {
  background-position: -600px -3480px !important;
}

.r-1425 {
  background-position: -640px -3480px !important;
}

.r-1424 {
  background-position: -680px -3480px !important;
}

.r-1423 {
  background-position: -720px -3480px !important;
}

.r-1422 {
  background-position: -760px -3480px !important;
}

.r-1421 {
  background-position: -800px -3480px !important;
}

.r-1420 {
  background-position: -840px -3480px !important;
}

.r-1419 {
  background-position: -880px -3480px !important;
}

.r-1418 {
  background-position: -920px -3480px !important;
}

.r-1417 {
  background-position: -960px -3480px !important;
}

.r-1416 {
  background-position: 0 -3520px !important;
}

.r-1406 {
  background-position: -40px -3520px !important;
}

.r-1405 {
  background-position: -80px -3520px !important;
}

.r-1404 {
  background-position: -120px -3520px !important;
}

.r-1403 {
  background-position: -160px -3520px !important;
}

.r-1402 {
  background-position: -200px -3520px !important;
}

.r-1401 {
  background-position: -240px -3520px !important;
}

.r-1400 {
  background-position: -280px -3520px !important;
}

.r-1399 {
  background-position: -320px -3520px !important;
}

.r-1398 {
  background-position: -360px -3520px !important;
}

.r-1390 {
  background-position: -400px -3520px !important;
}

.r-1389 {
  background-position: -440px -3520px !important;
}

.r-1388 {
  background-position: -480px -3520px !important;
}

.r-1387 {
  background-position: -520px -3520px !important;
}

.r-1386 {
  background-position: -560px -3520px !important;
}

.r-1385 {
  background-position: -600px -3520px !important;
}

.r-1384 {
  background-position: -640px -3520px !important;
}

.r-1383 {
  background-position: -680px -3520px !important;
}

.r-1382 {
  background-position: -720px -3520px !important;
}

.r-1381 {
  background-position: -760px -3520px !important;
}

.r-1380 {
  background-position: -800px -3520px !important;
}

.r-1379 {
  background-position: -840px -3520px !important;
}

.r-1378 {
  background-position: -880px -3520px !important;
}

.r-1377 {
  background-position: -920px -3520px !important;
}

.r-1376 {
  background-position: -960px -3520px !important;
}

.r-1375 {
  background-position: 0 -3560px !important;
}

.r-1364 {
  background-position: -40px -3560px !important;
}

.r-1363 {
  background-position: -80px -3560px !important;
}

.r-1362 {
  background-position: -120px -3560px !important;
}

.r-1361 {
  background-position: -160px -3560px !important;
}

.r-1360 {
  background-position: -200px -3560px !important;
}

.r-1359 {
  background-position: -240px -3560px !important;
}

.r-1358 {
  background-position: -280px -3560px !important;
}

.r-1357 {
  background-position: -320px -3560px !important;
}

.r-1356 {
  background-position: -360px -3560px !important;
}

.r-1354 {
  background-position: -400px -3560px !important;
}

.r-1353 {
  background-position: -440px -3560px !important;
}

.r-1352 {
  background-position: -480px -3560px !important;
}

.r-1351 {
  background-position: -520px -3560px !important;
}

.r-1350 {
  background-position: -560px -3560px !important;
}

.r-1349 {
  background-position: -600px -3560px !important;
}

.r-1348 {
  background-position: -640px -3560px !important;
}

.r-1347 {
  background-position: -680px -3560px !important;
}

.r-1346 {
  background-position: -720px -3560px !important;
}

.r-1345 {
  background-position: -760px -3560px !important;
}

.r-1344 {
  background-position: -800px -3560px !important;
}

.r-1343 {
  background-position: -840px -3560px !important;
}

.r-1342 {
  background-position: -880px -3560px !important;
}

.r-1341 {
  background-position: -920px -3560px !important;
}

.r-1340 {
  background-position: -960px -3560px !important;
}

.r-1339 {
  background-position: 0 -3600px !important;
}

.r-1338 {
  background-position: -40px -3600px !important;
}

.r-1337 {
  background-position: -80px -3600px !important;
}

.r-1336 {
  background-position: -120px -3600px !important;
}

.r-1335 {
  background-position: -160px -3600px !important;
}

.r-1334 {
  background-position: -200px -3600px !important;
}

.r-1333 {
  background-position: -240px -3600px !important;
}

.r-1332 {
  background-position: -280px -3600px !important;
}

.r-1329 {
  background-position: -320px -3600px !important;
}

.r-1328 {
  background-position: -360px -3600px !important;
}

.r-1327 {
  background-position: -400px -3600px !important;
}

.r-1326 {
  background-position: -440px -3600px !important;
}

.r-1324 {
  background-position: -480px -3600px !important;
}

.r-1323 {
  background-position: -520px -3600px !important;
}

.r-1322 {
  background-position: -560px -3600px !important;
}

.r-1308 {
  background-position: -600px -3600px !important;
}

.r-1307 {
  background-position: -640px -3600px !important;
}

.r-1306 {
  background-position: -680px -3600px !important;
}

.r-1305 {
  background-position: -720px -3600px !important;
}

.r-1304 {
  background-position: -760px -3600px !important;
}

.r-1303 {
  background-position: -800px -3600px !important;
}

.r-1300 {
  background-position: -840px -3600px !important;
}

.r-1299 {
  background-position: -880px -3600px !important;
}

.r-1298 {
  background-position: -920px -3600px !important;
}

.r-1297 {
  background-position: -960px -3600px !important;
}

.r-1296 {
  background-position: 0 -3640px !important;
}

.r-1295 {
  background-position: -40px -3640px !important;
}

.r-1294 {
  background-position: -80px -3640px !important;
}

.r-1293 {
  background-position: -120px -3640px !important;
}

.r-1292 {
  background-position: -160px -3640px !important;
}

.r-1291 {
  background-position: -200px -3640px !important;
}

.r-1290 {
  background-position: -240px -3640px !important;
}

.r-1286 {
  background-position: -280px -3640px !important;
}

.r-1285 {
  background-position: -320px -3640px !important;
}

.r-1284 {
  background-position: -360px -3640px !important;
}

.r-1283 {
  background-position: -400px -3640px !important;
}

.r-1282 {
  background-position: -440px -3640px !important;
}

.r-1281 {
  background-position: -480px -3640px !important;
}

.r-1280 {
  background-position: -520px -3640px !important;
}

.r-1279 {
  background-position: -560px -3640px !important;
}

.r-1278 {
  background-position: -600px -3640px !important;
}

.r-1275 {
  background-position: -640px -3640px !important;
}

.r-1274 {
  background-position: -680px -3640px !important;
}

.r-1273 {
  background-position: -720px -3640px !important;
}

.r-1272 {
  background-position: -760px -3640px !important;
}

.r-1271 {
  background-position: -800px -3640px !important;
}

.r-1270 {
  background-position: -840px -3640px !important;
}

.r-1269 {
  background-position: -880px -3640px !important;
}

.r-1268 {
  background-position: -920px -3640px !important;
}

.r-1261 {
  background-position: -960px -3640px !important;
}

.r-1260 {
  background-position: 0 -3680px !important;
}

.r-1259 {
  background-position: -40px -3680px !important;
}

.r-1258 {
  background-position: -80px -3680px !important;
}

.r-1257 {
  background-position: -120px -3680px !important;
}

.r-1256 {
  background-position: -160px -3680px !important;
}

.r-1255 {
  background-position: -200px -3680px !important;
}

.r-1254 {
  background-position: -240px -3680px !important;
}

.r-1253 {
  background-position: -280px -3680px !important;
}

.r-1252 {
  background-position: -320px -3680px !important;
}

.r-1250 {
  background-position: -360px -3680px !important;
}

.r-1249 {
  background-position: -400px -3680px !important;
}

.r-1248 {
  background-position: -440px -3680px !important;
}

.r-1247 {
  background-position: -480px -3680px !important;
}

.r-1246 {
  background-position: -520px -3680px !important;
}

.r-1245 {
  background-position: -560px -3680px !important;
}

.r-1244 {
  background-position: -600px -3680px !important;
}

.r-1243 {
  background-position: -640px -3680px !important;
}

.r-1242 {
  background-position: -680px -3680px !important;
}

.r-1230 {
  background-position: -720px -3680px !important;
}

.r-1229 {
  background-position: -760px -3680px !important;
}

.r-1228 {
  background-position: -800px -3680px !important;
}

.r-1227 {
  background-position: -840px -3680px !important;
}

.r-1226 {
  background-position: -880px -3680px !important;
}

.r-1225 {
  background-position: -920px -3680px !important;
}

.r-1219 {
  background-position: -960px -3680px !important;
}

.r-1218 {
  background-position: 0 -3720px !important;
}

.r-1217 {
  background-position: -40px -3720px !important;
}

.r-1216 {
  background-position: -80px -3720px !important;
}

.r-1215 {
  background-position: -120px -3720px !important;
}

.r-1212 {
  background-position: -160px -3720px !important;
}

.r-1211 {
  background-position: -200px -3720px !important;
}

.r-1210 {
  background-position: -240px -3720px !important;
}

.r-1209 {
  background-position: -280px -3720px !important;
}

.r-1208 {
  background-position: -320px -3720px !important;
}

.r-1207 {
  background-position: -360px -3720px !important;
}

.r-1206 {
  background-position: -400px -3720px !important;
}

.r-1205 {
  background-position: -440px -3720px !important;
}

.r-1203 {
  background-position: -480px -3720px !important;
}

.r-1202 {
  background-position: -520px -3720px !important;
}

.r-1201 {
  background-position: -560px -3720px !important;
}

.r-1200 {
  background-position: -600px -3720px !important;
}

.r-1199 {
  background-position: -640px -3720px !important;
}

.r-1198 {
  background-position: -680px -3720px !important;
}

.r-1197 {
  background-position: -720px -3720px !important;
}

.r-1196 {
  background-position: -760px -3720px !important;
}

.r-1195 {
  background-position: -800px -3720px !important;
}

.r-1194 {
  background-position: -840px -3720px !important;
}

.r-1185 {
  background-position: -880px -3720px !important;
}

.r-1184 {
  background-position: -920px -3720px !important;
}

.r-1183 {
  background-position: -960px -3720px !important;
}

.r-1182 {
  background-position: 0 -3760px !important;
}

.r-1181 {
  background-position: -40px -3760px !important;
}

.r-1180 {
  background-position: -80px -3760px !important;
}

.r-1179 {
  background-position: -120px -3760px !important;
}

.r-1178 {
  background-position: -160px -3760px !important;
}

.r-1177 {
  background-position: -200px -3760px !important;
}

.r-1169 {
  background-position: -240px -3760px !important;
}

.r-1168 {
  background-position: -280px -3760px !important;
}

.r-1167 {
  background-position: -320px -3760px !important;
}

.r-1166 {
  background-position: -360px -3760px !important;
}

.r-1165 {
  background-position: -400px -3760px !important;
}

.r-1164 {
  background-position: -440px -3760px !important;
}

.r-1154 {
  background-position: -480px -3760px !important;
}

.r-1153 {
  background-position: -520px -3760px !important;
}

.r-1152 {
  background-position: -560px -3760px !important;
}

.r-1151 {
  background-position: -600px -3760px !important;
}

.r-1150 {
  background-position: -640px -3760px !important;
}

.r-1149 {
  background-position: -680px -3760px !important;
}

.r-1148 {
  background-position: -720px -3760px !important;
}

.r-1144 {
  background-position: -760px -3760px !important;
}

.r-1143 {
  background-position: -800px -3760px !important;
}

.r-1142 {
  background-position: -840px -3760px !important;
}

.r-1141 {
  background-position: -880px -3760px !important;
}

.r-1140 {
  background-position: -920px -3760px !important;
}

.r-1139 {
  background-position: -960px -3760px !important;
}

.r-1138 {
  background-position: 0 -3800px !important;
}

.r-1137 {
  background-position: -40px -3800px !important;
}

.r-1135 {
  background-position: -80px -3800px !important;
}

.r-1134 {
  background-position: -120px -3800px !important;
}

.r-1133 {
  background-position: -160px -3800px !important;
}

.r-1132 {
  background-position: -200px -3800px !important;
}

.r-1131 {
  background-position: -240px -3800px !important;
}

.r-1130 {
  background-position: -280px -3800px !important;
}

.r-1129 {
  background-position: -320px -3800px !important;
}

.r-1128 {
  background-position: -360px -3800px !important;
}

.r-1120 {
  background-position: -400px -3800px !important;
}

.r-1119 {
  background-position: -440px -3800px !important;
}

.r-1118 {
  background-position: -480px -3800px !important;
}

.r-1117 {
  background-position: -520px -3800px !important;
}

.r-1116 {
  background-position: -560px -3800px !important;
}

.r-1115 {
  background-position: -600px -3800px !important;
}

.r-1114 {
  background-position: -640px -3800px !important;
}

.r-1113 {
  background-position: -680px -3800px !important;
}

.r-1112 {
  background-position: -720px -3800px !important;
}

.r-1111 {
  background-position: -760px -3800px !important;
}

.r-1110 {
  background-position: -800px -3800px !important;
}

.r-1109 {
  background-position: -840px -3800px !important;
}

.r-1106 {
  background-position: -880px -3800px !important;
}

.r-1105 {
  background-position: -920px -3800px !important;
}

.r-1104 {
  background-position: -960px -3800px !important;
}

.r-1103 {
  background-position: 0 -3840px !important;
}

.r-1102 {
  background-position: -40px -3840px !important;
}

.r-1101 {
  background-position: -80px -3840px !important;
}

.r-1100 {
  background-position: -120px -3840px !important;
}

.r-1099 {
  background-position: -160px -3840px !important;
}

.r-1098 {
  background-position: -200px -3840px !important;
}

.r-1097 {
  background-position: -240px -3840px !important;
}

.r-1096 {
  background-position: -280px -3840px !important;
}

.r-1095 {
  background-position: -320px -3840px !important;
}

.r-1094 {
  background-position: -360px -3840px !important;
}

.r-1093 {
  background-position: -400px -3840px !important;
}

.r-1091 {
  background-position: -440px -3840px !important;
}

.r-1090 {
  background-position: -480px -3840px !important;
}

.r-1089 {
  background-position: -520px -3840px !important;
}

.r-1088 {
  background-position: -560px -3840px !important;
}

.r-1087 {
  background-position: -600px -3840px !important;
}

.r-1086 {
  background-position: -640px -3840px !important;
}

.r-1085 {
  background-position: -680px -3840px !important;
}

.r-1084 {
  background-position: -720px -3840px !important;
}

.r-1083 {
  background-position: -760px -3840px !important;
}

.r-1079 {
  background-position: -800px -3840px !important;
}

.r-1077 {
  background-position: -840px -3840px !important;
}

.r-1076 {
  background-position: -880px -3840px !important;
}

.r-1075 {
  background-position: -920px -3840px !important;
}

.r-1074 {
  background-position: -960px -3840px !important;
}

.r-1073 {
  background-position: 0 -3880px !important;
}

.r-1072 {
  background-position: -40px -3880px !important;
}

.r-1071 {
  background-position: -80px -3880px !important;
}

.r-1070 {
  background-position: -120px -3880px !important;
}

.r-1069 {
  background-position: -160px -3880px !important;
}

.r-1068 {
  background-position: -200px -3880px !important;
}

.r-1067 {
  background-position: -240px -3880px !important;
}

.r-1066 {
  background-position: -280px -3880px !important;
}

.r-1064 {
  background-position: -320px -3880px !important;
}

.r-1063 {
  background-position: -360px -3880px !important;
}

.r-1062 {
  background-position: -400px -3880px !important;
}

.r-1061 {
  background-position: -440px -3880px !important;
}

.r-1060 {
  background-position: -480px -3880px !important;
}

.r-1059 {
  background-position: -520px -3880px !important;
}

.r-1058 {
  background-position: -560px -3880px !important;
}

.r-1057 {
  background-position: -600px -3880px !important;
}

.r-1056 {
  background-position: -640px -3880px !important;
}

.r-1053 {
  background-position: -680px -3880px !important;
}

.r-1052 {
  background-position: -720px -3880px !important;
}

.r-1051 {
  background-position: -760px -3880px !important;
}

.r-1050 {
  background-position: -800px -3880px !important;
}

.r-1049 {
  background-position: -840px -3880px !important;
}

.r-1048 {
  background-position: -880px -3880px !important;
}

.r-1047 {
  background-position: -920px -3880px !important;
}

.r-1046 {
  background-position: -960px -3880px !important;
}

.r-1045 {
  background-position: 0 -3920px !important;
}

.r-1044 {
  background-position: -40px -3920px !important;
}

.r-1043 {
  background-position: -80px -3920px !important;
}

.r-1042 {
  background-position: -120px -3920px !important;
}

.r-1041 {
  background-position: -160px -3920px !important;
}

.r-1040 {
  background-position: -200px -3920px !important;
}

.r-1039 {
  background-position: -240px -3920px !important;
}

.r-1038 {
  background-position: -280px -3920px !important;
}

.r-1037 {
  background-position: -320px -3920px !important;
}

.r-1036 {
  background-position: -360px -3920px !important;
}

.r-1035 {
  background-position: -400px -3920px !important;
}

.r-1034 {
  background-position: -440px -3920px !important;
}

.r-1033 {
  background-position: -480px -3920px !important;
}

.r-1032 {
  background-position: -520px -3920px !important;
}

.r-1031 {
  background-position: -560px -3920px !important;
}

.r-1030 {
  background-position: -600px -3920px !important;
}

.r-1029 {
  background-position: -640px -3920px !important;
}

.r-1028 {
  background-position: -680px -3920px !important;
}

.r-1027 {
  background-position: -720px -3920px !important;
}

.r-1026 {
  background-position: -760px -3920px !important;
}

.r-1025 {
  background-position: -800px -3920px !important;
}

.r-1024 {
  background-position: -840px -3920px !important;
}

.r-1023 {
  background-position: -880px -3920px !important;
}

.r-1022 {
  background-position: -920px -3920px !important;
}

.r-1019 {
  background-position: -960px -3920px !important;
}

.r-1018 {
  background-position: 0 -3960px !important;
}

.r-1017 {
  background-position: -40px -3960px !important;
}

.r-1016 {
  background-position: -80px -3960px !important;
}

.r-1015 {
  background-position: -120px -3960px !important;
}

.r-1014 {
  background-position: -160px -3960px !important;
}

.r-1013 {
  background-position: -200px -3960px !important;
}

.r-1012 {
  background-position: -240px -3960px !important;
}

.r-1011 {
  background-position: -280px -3960px !important;
}

.r-1010 {
  background-position: -320px -3960px !important;
}

.r-1009 {
  background-position: -360px -3960px !important;
}

.r-1007 {
  background-position: -400px -3960px !important;
}

.r-1004 {
  background-position: -440px -3960px !important;
}

.r-1003 {
  background-position: -480px -3960px !important;
}

.r-1002 {
  background-position: -520px -3960px !important;
}

.r-1001 {
  background-position: -560px -3960px !important;
}

.r-1000 {
  background-position: -600px -3960px !important;
}

.r-999 {
  background-position: -640px -3960px !important;
}

.r-998 {
  background-position: -680px -3960px !important;
}

.r-997 {
  background-position: -720px -3960px !important;
}

.r-996 {
  background-position: -760px -3960px !important;
}

.r-995 {
  background-position: -800px -3960px !important;
}

.r-994 {
  background-position: -840px -3960px !important;
}

.r-993 {
  background-position: -880px -3960px !important;
}

.r-992 {
  background-position: -920px -3960px !important;
}

.r-978 {
  background-position: -960px -3960px !important;
}

.r-977 {
  background-position: 0 -4000px !important;
}

.r-976 {
  background-position: -40px -4000px !important;
}

.r-975 {
  background-position: -80px -4000px !important;
}

.r-974 {
  background-position: -120px -4000px !important;
}

.r-973 {
  background-position: -160px -4000px !important;
}

.r-972 {
  background-position: -200px -4000px !important;
}

.r-971 {
  background-position: -240px -4000px !important;
}

.r-970 {
  background-position: -280px -4000px !important;
}

.r-969 {
  background-position: -320px -4000px !important;
}

.r-968 {
  background-position: -360px -4000px !important;
}

.r-967 {
  background-position: -400px -4000px !important;
}

.r-966 {
  background-position: -440px -4000px !important;
}

.r-965 {
  background-position: -480px -4000px !important;
}

.r-963 {
  background-position: -520px -4000px !important;
}

.r-962 {
  background-position: -560px -4000px !important;
}

.r-961 {
  background-position: -600px -4000px !important;
}

.r-960 {
  background-position: -640px -4000px !important;
}

.r-959 {
  background-position: -680px -4000px !important;
}

.r-958 {
  background-position: -720px -4000px !important;
}

.r-957 {
  background-position: -760px -4000px !important;
}

.r-956 {
  background-position: -800px -4000px !important;
}

.r-955 {
  background-position: -840px -4000px !important;
}

.r-954 {
  background-position: -880px -4000px !important;
}

.r-953 {
  background-position: -920px -4000px !important;
}

.r-952 {
  background-position: -960px -4000px !important;
}

.r-950 {
  background-position: 0 -4040px !important;
}

.r-949 {
  background-position: -40px -4040px !important;
}

.r-948 {
  background-position: -80px -4040px !important;
}

.r-947 {
  background-position: -120px -4040px !important;
}

.r-946 {
  background-position: -160px -4040px !important;
}

.r-945 {
  background-position: -200px -4040px !important;
}

.r-944 {
  background-position: -240px -4040px !important;
}

.r-943 {
  background-position: -280px -4040px !important;
}

.r-942 {
  background-position: -320px -4040px !important;
}

.r-941 {
  background-position: -360px -4040px !important;
}

.r-940 {
  background-position: -400px -4040px !important;
}

.r-939 {
  background-position: -440px -4040px !important;
}

.r-938 {
  background-position: -480px -4040px !important;
}

.r-937 {
  background-position: -520px -4040px !important;
}

.r-936 {
  background-position: -560px -4040px !important;
}

.r-935 {
  background-position: -600px -4040px !important;
}

.r-934 {
  background-position: -640px -4040px !important;
}

.r-933 {
  background-position: -680px -4040px !important;
}

.r-924 {
  background-position: -720px -4040px !important;
}

.r-923 {
  background-position: -760px -4040px !important;
}

.r-922 {
  background-position: -800px -4040px !important;
}

.r-921 {
  background-position: -840px -4040px !important;
}

.r-920 {
  background-position: -880px -4040px !important;
}

.r-919 {
  background-position: -920px -4040px !important;
}

.r-918 {
  background-position: -960px -4040px !important;
}

.r-917 {
  background-position: 0 -4080px !important;
}

.r-916 {
  background-position: -40px -4080px !important;
}

.r-915 {
  background-position: -80px -4080px !important;
}

.r-914 {
  background-position: -120px -4080px !important;
}

.r-913 {
  background-position: -160px -4080px !important;
}

.r-912 {
  background-position: -200px -4080px !important;
}

.r-911 {
  background-position: -240px -4080px !important;
}

.r-910 {
  background-position: -280px -4080px !important;
}

.r-909 {
  background-position: -320px -4080px !important;
}

.r-908 {
  background-position: -360px -4080px !important;
}

.r-904 {
  background-position: -400px -4080px !important;
}

.r-903 {
  background-position: -440px -4080px !important;
}

.r-902 {
  background-position: -480px -4080px !important;
}

.r-901 {
  background-position: -520px -4080px !important;
}

.r-900 {
  background-position: -560px -4080px !important;
}

.r-899 {
  background-position: -600px -4080px !important;
}

.r-898 {
  background-position: -640px -4080px !important;
}

.r-897 {
  background-position: -680px -4080px !important;
}

.r-896 {
  background-position: -720px -4080px !important;
}

.r-895 {
  background-position: -760px -4080px !important;
}

.r-894 {
  background-position: -800px -4080px !important;
}

.r-893 {
  background-position: -840px -4080px !important;
}

.r-892 {
  background-position: -880px -4080px !important;
}

.r-883 {
  background-position: -920px -4080px !important;
}

.r-882 {
  background-position: -960px -4080px !important;
}

.r-881 {
  background-position: 0 -4120px !important;
}

.r-880 {
  background-position: -40px -4120px !important;
}

.r-879 {
  background-position: -80px -4120px !important;
}

.r-878 {
  background-position: -120px -4120px !important;
}

.r-877 {
  background-position: -160px -4120px !important;
}

.r-876 {
  background-position: -200px -4120px !important;
}

.r-875 {
  background-position: -240px -4120px !important;
}

.r-874 {
  background-position: -280px -4120px !important;
}

.r-873 {
  background-position: -320px -4120px !important;
}

.r-872 {
  background-position: -360px -4120px !important;
}

.r-871 {
  background-position: -400px -4120px !important;
}

.r-870 {
  background-position: -440px -4120px !important;
}

.r-869 {
  background-position: -480px -4120px !important;
}

.r-868 {
  background-position: -520px -4120px !important;
}

.r-863 {
  background-position: -560px -4120px !important;
}

.r-862 {
  background-position: -600px -4120px !important;
}

.r-861 {
  background-position: -640px -4120px !important;
}

.r-860 {
  background-position: -680px -4120px !important;
}

.r-859 {
  background-position: -720px -4120px !important;
}

.r-858 {
  background-position: -760px -4120px !important;
}

.r-857 {
  background-position: -800px -4120px !important;
}

.r-856 {
  background-position: -840px -4120px !important;
}

.r-855 {
  background-position: -880px -4120px !important;
}

.r-854 {
  background-position: -920px -4120px !important;
}

.r-853 {
  background-position: -960px -4120px !important;
}

.r-852 {
  background-position: 0 -4160px !important;
}

.r-851 {
  background-position: -40px -4160px !important;
}

.r-850 {
  background-position: -80px -4160px !important;
}

.r-849 {
  background-position: -120px -4160px !important;
}

.r-848 {
  background-position: -160px -4160px !important;
}

.r-847 {
  background-position: -200px -4160px !important;
}

.r-846 {
  background-position: -240px -4160px !important;
}

.r-844 {
  background-position: -280px -4160px !important;
}

.r-843 {
  background-position: -320px -4160px !important;
}

.r-842 {
  background-position: -360px -4160px !important;
}

.r-841 {
  background-position: -400px -4160px !important;
}

.r-840 {
  background-position: -440px -4160px !important;
}

.r-839 {
  background-position: -480px -4160px !important;
}

.r-838 {
  background-position: -520px -4160px !important;
}

.r-837 {
  background-position: -560px -4160px !important;
}

.r-836 {
  background-position: -600px -4160px !important;
}

.r-835 {
  background-position: -640px -4160px !important;
}

.r-834 {
  background-position: -680px -4160px !important;
}

.r-833 {
  background-position: -720px -4160px !important;
}

.r-832 {
  background-position: -760px -4160px !important;
}

.r-831 {
  background-position: -800px -4160px !important;
}

.r-830 {
  background-position: -840px -4160px !important;
}

.r-829 {
  background-position: -880px -4160px !important;
}

.r-828 {
  background-position: -920px -4160px !important;
}

.r-826 {
  background-position: -960px -4160px !important;
}

.r-825 {
  background-position: 0 -4200px !important;
}

.r-814 {
  background-position: -40px -4200px !important;
}

.r-813 {
  background-position: -80px -4200px !important;
}

.r-812 {
  background-position: -120px -4200px !important;
}

.r-811 {
  background-position: -160px -4200px !important;
}

.r-810 {
  background-position: -200px -4200px !important;
}

.r-809 {
  background-position: -240px -4200px !important;
}

.r-808 {
  background-position: -280px -4200px !important;
}

.r-807 {
  background-position: -320px -4200px !important;
}

.r-806 {
  background-position: -360px -4200px !important;
}

.r-805 {
  background-position: -400px -4200px !important;
}

.r-804 {
  background-position: -440px -4200px !important;
}

.r-803 {
  background-position: -480px -4200px !important;
}

.r-802 {
  background-position: -520px -4200px !important;
}

.r-801 {
  background-position: -560px -4200px !important;
}

.r-800 {
  background-position: -600px -4200px !important;
}

.r-799 {
  background-position: -640px -4200px !important;
}

.r-798 {
  background-position: -680px -4200px !important;
}

.r-797 {
  background-position: -720px -4200px !important;
}

.r-795 {
  background-position: -760px -4200px !important;
}

.r-794 {
  background-position: -800px -4200px !important;
}

.r-793 {
  background-position: -840px -4200px !important;
}

.r-792 {
  background-position: -880px -4200px !important;
}

.r-791 {
  background-position: -920px -4200px !important;
}

.r-790 {
  background-position: -960px -4200px !important;
}

.r-789 {
  background-position: 0 -4240px !important;
}

.r-788 {
  background-position: -40px -4240px !important;
}

.r-787 {
  background-position: -80px -4240px !important;
}

.r-786 {
  background-position: -120px -4240px !important;
}

.r-785 {
  background-position: -160px -4240px !important;
}

.r-784 {
  background-position: -200px -4240px !important;
}

.r-783 {
  background-position: -240px -4240px !important;
}

.r-782 {
  background-position: -280px -4240px !important;
}

.r-781 {
  background-position: -320px -4240px !important;
}

.r-780 {
  background-position: -360px -4240px !important;
}

.r-779 {
  background-position: -400px -4240px !important;
}

.r-778 {
  background-position: -440px -4240px !important;
}

.r-774 {
  background-position: -480px -4240px !important;
}

.r-773 {
  background-position: -520px -4240px !important;
}

.r-767 {
  background-position: -560px -4240px !important;
}

.r-758 {
  background-position: -600px -4240px !important;
}

.r-757 {
  background-position: -640px -4240px !important;
}

.r-756 {
  background-position: -680px -4240px !important;
}

.r-755 {
  background-position: -720px -4240px !important;
}

.r-754 {
  background-position: -760px -4240px !important;
}

.r-753 {
  background-position: -800px -4240px !important;
}

.r-752 {
  background-position: -840px -4240px !important;
}

.r-751 {
  background-position: -880px -4240px !important;
}

.r-750 {
  background-position: -920px -4240px !important;
}

.r-749 {
  background-position: -960px -4240px !important;
}

.r-748 {
  background-position: 0 -4280px !important;
}

.r-747 {
  background-position: -40px -4280px !important;
}

.r-746 {
  background-position: -80px -4280px !important;
}

.r-745 {
  background-position: -120px -4280px !important;
}

.r-744 {
  background-position: -160px -4280px !important;
}

.r-743 {
  background-position: -200px -4280px !important;
}

.r-742 {
  background-position: -240px -4280px !important;
}

.r-741 {
  background-position: -280px -4280px !important;
}

.r-712 {
  background-position: -320px -4280px !important;
}

.r-711 {
  background-position: -360px -4280px !important;
}

.r-710 {
  background-position: -400px -4280px !important;
}

.r-709 {
  background-position: -440px -4280px !important;
}

.r-708 {
  background-position: -480px -4280px !important;
}

.r-707 {
  background-position: -520px -4280px !important;
}

.r-706 {
  background-position: -560px -4280px !important;
}

.r-705 {
  background-position: -600px -4280px !important;
}

.r-704 {
  background-position: -640px -4280px !important;
}

.r-703 {
  background-position: -680px -4280px !important;
}

.r-702 {
  background-position: -720px -4280px !important;
}

.r-701 {
  background-position: -760px -4280px !important;
}

.r-700 {
  background-position: -800px -4280px !important;
}

.r-699 {
  background-position: -840px -4280px !important;
}

.r-698 {
  background-position: -880px -4280px !important;
}

.r-697 {
  background-position: -920px -4280px !important;
}

.r-696 {
  background-position: -960px -4280px !important;
}

.r-695 {
  background-position: 0 -4320px !important;
}

.r-694 {
  background-position: -40px -4320px !important;
}

.r-693 {
  background-position: -80px -4320px !important;
}

.r-692 {
  background-position: -120px -4320px !important;
}

.r-690 {
  background-position: -160px -4320px !important;
}

.r-689 {
  background-position: -200px -4320px !important;
}

.r-675 {
  background-position: -240px -4320px !important;
}

.r-674 {
  background-position: -280px -4320px !important;
}

.r-673 {
  background-position: -320px -4320px !important;
}

.r-672 {
  background-position: -360px -4320px !important;
}

.r-671 {
  background-position: -400px -4320px !important;
}

.r-670 {
  background-position: -440px -4320px !important;
}

.r-669 {
  background-position: -480px -4320px !important;
}

.r-668 {
  background-position: -520px -4320px !important;
}

.r-667 {
  background-position: -560px -4320px !important;
}

.r-666 {
  background-position: -600px -4320px !important;
}

.r-665 {
  background-position: -640px -4320px !important;
}

.r-664 {
  background-position: -680px -4320px !important;
}

.r-663 {
  background-position: -720px -4320px !important;
}

.r-662 {
  background-position: -760px -4320px !important;
}

.r-661 {
  background-position: -800px -4320px !important;
}

.r-660 {
  background-position: -840px -4320px !important;
}

.r-659 {
  background-position: -880px -4320px !important;
}

.r-658 {
  background-position: -920px -4320px !important;
}

.r-643 {
  background-position: -960px -4320px !important;
}

.r-642 {
  background-position: 0 -4360px !important;
}

.r-641 {
  background-position: -40px -4360px !important;
}

.r-640 {
  background-position: -80px -4360px !important;
}

.r-639 {
  background-position: -120px -4360px !important;
}

.r-638 {
  background-position: -160px -4360px !important;
}

.r-637 {
  background-position: -200px -4360px !important;
}

.r-636 {
  background-position: -240px -4360px !important;
}

.r-635 {
  background-position: -280px -4360px !important;
}

.r-634 {
  background-position: -320px -4360px !important;
}

.r-633 {
  background-position: -360px -4360px !important;
}

.r-632 {
  background-position: -400px -4360px !important;
}

.r-631 {
  background-position: -440px -4360px !important;
}

.r-630 {
  background-position: -480px -4360px !important;
}

.r-629 {
  background-position: -520px -4360px !important;
}

.r-628 {
  background-position: -560px -4360px !important;
}

.r-627 {
  background-position: -600px -4360px !important;
}

.r-626 {
  background-position: -640px -4360px !important;
}

.r-616 {
  background-position: -680px -4360px !important;
}

.r-590 {
  background-position: -720px -4360px !important;
}

.r-589 {
  background-position: -760px -4360px !important;
}

.r-588 {
  background-position: -800px -4360px !important;
}

.r-587 {
  background-position: -840px -4360px !important;
}

.r-586 {
  background-position: -880px -4360px !important;
}

.r-585 {
  background-position: -920px -4360px !important;
}

.r-584 {
  background-position: -960px -4360px !important;
}

.r-583 {
  background-position: 0 -4400px !important;
}

.r-582 {
  background-position: -40px -4400px !important;
}

.r-581 {
  background-position: -80px -4400px !important;
}

.r-580 {
  background-position: -120px -4400px !important;
}

.r-579 {
  background-position: -160px -4400px !important;
}

.r-578 {
  background-position: -200px -4400px !important;
}

.r-577 {
  background-position: -240px -4400px !important;
}

.r-576 {
  background-position: -280px -4400px !important;
}

.r-575 {
  background-position: -320px -4400px !important;
}

.r-574 {
  background-position: -360px -4400px !important;
}

.r-573 {
  background-position: -400px -4400px !important;
}

.r-552 {
  background-position: -440px -4400px !important;
}

.r-551 {
  background-position: -480px -4400px !important;
}

.r-550 {
  background-position: -520px -4400px !important;
}

.r-549 {
  background-position: -560px -4400px !important;
}

.r-548 {
  background-position: -600px -4400px !important;
}

.r-547 {
  background-position: -640px -4400px !important;
}

.r-546 {
  background-position: -680px -4400px !important;
}

.r-545 {
  background-position: -720px -4400px !important;
}

.r-544 {
  background-position: -760px -4400px !important;
}

.r-543 {
  background-position: -800px -4400px !important;
}

.r-542 {
  background-position: -840px -4400px !important;
}

.r-541 {
  background-position: -880px -4400px !important;
}

.r-540 {
  background-position: -920px -4400px !important;
}

.r-539 {
  background-position: -960px -4400px !important;
}

.r-538 {
  background-position: 0 -4440px !important;
}

.r-537 {
  background-position: -40px -4440px !important;
}

.r-536 {
  background-position: -80px -4440px !important;
}

.r-535 {
  background-position: -120px -4440px !important;
}

.r-526 {
  background-position: -160px -4440px !important;
}

.r-525 {
  background-position: -200px -4440px !important;
}

.r-508 {
  background-position: -240px -4440px !important;
}

.r-507 {
  background-position: -280px -4440px !important;
}

.r-506 {
  background-position: -320px -4440px !important;
}

.r-505 {
  background-position: -360px -4440px !important;
}

.r-504 {
  background-position: -400px -4440px !important;
}

.r-502 {
  background-position: -440px -4440px !important;
}

.r-501 {
  background-position: -480px -4440px !important;
}

.r-500 {
  background-position: -520px -4440px !important;
}

.r-499 {
  background-position: -560px -4440px !important;
}

.r-498 {
  background-position: -600px -4440px !important;
}

.r-497 {
  background-position: -640px -4440px !important;
}

.r-493 {
  background-position: -680px -4440px !important;
}

.r-492 {
  background-position: -720px -4440px !important;
}

.r-491 {
  background-position: -760px -4440px !important;
}

.r-490 {
  background-position: -800px -4440px !important;
}

.r-489 {
  background-position: -840px -4440px !important;
}

.r-487 {
  background-position: -880px -4440px !important;
}

.r-486 {
  background-position: -920px -4440px !important;
}

.r-485 {
  background-position: -960px -4440px !important;
}

.r-482 {
  background-position: 0 -4480px !important;
}

.r-481 {
  background-position: -40px -4480px !important;
}

.r-480 {
  background-position: -80px -4480px !important;
}

.r-479 {
  background-position: -120px -4480px !important;
}

.r-478 {
  background-position: -160px -4480px !important;
}

.r-477 {
  background-position: -200px -4480px !important;
}

.r-476 {
  background-position: -240px -4480px !important;
}

.r-468 {
  background-position: -280px -4480px !important;
}

.r-467 {
  background-position: -320px -4480px !important;
}

.r-466 {
  background-position: -360px -4480px !important;
}

.r-465 {
  background-position: -400px -4480px !important;
}

.r-464 {
  background-position: -440px -4480px !important;
}

.r-463 {
  background-position: -480px -4480px !important;
}

.r-462 {
  background-position: -520px -4480px !important;
}

.r-461 {
  background-position: -560px -4480px !important;
}

.r-458 {
  background-position: -600px -4480px !important;
}

.r-457 {
  background-position: -640px -4480px !important;
}

.r-456 {
  background-position: -680px -4480px !important;
}

.r-455 {
  background-position: -720px -4480px !important;
}

.r-454 {
  background-position: -760px -4480px !important;
}

.r-453 {
  background-position: -800px -4480px !important;
}

.r-452 {
  background-position: -840px -4480px !important;
}

.r-451 {
  background-position: -880px -4480px !important;
}

.r-450 {
  background-position: -920px -4480px !important;
}

.r-449 {
  background-position: -960px -4480px !important;
}

.r-448 {
  background-position: 0 -4520px !important;
}

.r-440 {
  background-position: -40px -4520px !important;
}

.r-439 {
  background-position: -80px -4520px !important;
}

.r-438 {
  background-position: -120px -4520px !important;
}

.r-437 {
  background-position: -160px -4520px !important;
}

.r-436 {
  background-position: -200px -4520px !important;
}

.r-435 {
  background-position: -240px -4520px !important;
}

.r-432 {
  background-position: -280px -4520px !important;
}

.r-431 {
  background-position: -320px -4520px !important;
}

.r-430 {
  background-position: -360px -4520px !important;
}

.r-429 {
  background-position: -400px -4520px !important;
}

.r-428 {
  background-position: -440px -4520px !important;
}

.r-427 {
  background-position: -480px -4520px !important;
}

.r-426 {
  background-position: -520px -4520px !important;
}

.r-425 {
  background-position: -560px -4520px !important;
}

.r-424 {
  background-position: -600px -4520px !important;
}

.r-423 {
  background-position: -640px -4520px !important;
}

.r-422 {
  background-position: -680px -4520px !important;
}

.r-421 {
  background-position: -720px -4520px !important;
}

.r-420 {
  background-position: -760px -4520px !important;
}

.r-419 {
  background-position: -800px -4520px !important;
}

.r-418 {
  background-position: -840px -4520px !important;
}

.r-417 {
  background-position: -880px -4520px !important;
}

.r-416 {
  background-position: -920px -4520px !important;
}

.r-415 {
  background-position: -960px -4520px !important;
}

.r-410 {
  background-position: 0 -4560px !important;
}

.r-409 {
  background-position: -40px -4560px !important;
}

.r-408 {
  background-position: -80px -4560px !important;
}

.r-407 {
  background-position: -120px -4560px !important;
}

.r-406 {
  background-position: -160px -4560px !important;
}

.r-405 {
  background-position: -200px -4560px !important;
}

.r-404 {
  background-position: -240px -4560px !important;
}

.r-403 {
  background-position: -280px -4560px !important;
}

.r-402 {
  background-position: -320px -4560px !important;
}

.r-400 {
  background-position: -360px -4560px !important;
}

.r-399 {
  background-position: -400px -4560px !important;
}

.r-398 {
  background-position: -440px -4560px !important;
}

.r-397 {
  background-position: -480px -4560px !important;
}

.r-396 {
  background-position: -520px -4560px !important;
}

.r-391 {
  background-position: -560px -4560px !important;
}

.r-390 {
  background-position: -600px -4560px !important;
}

.r-389 {
  background-position: -640px -4560px !important;
}

.r-388 {
  background-position: -680px -4560px !important;
}

.r-383 {
  background-position: -720px -4560px !important;
}

.r-382 {
  background-position: -760px -4560px !important;
}

.r-381 {
  background-position: -800px -4560px !important;
}

.r-380 {
  background-position: -840px -4560px !important;
}

.r-379 {
  background-position: -880px -4560px !important;
}

.r-373 {
  background-position: -920px -4560px !important;
}

.r-372 {
  background-position: -960px -4560px !important;
}

.r-371 {
  background-position: 0 -4600px !important;
}

.r-368 {
  background-position: -40px -4600px !important;
}

.r-367 {
  background-position: -80px -4600px !important;
}

.r-366 {
  background-position: -120px -4600px !important;
}

.r-365 {
  background-position: -160px -4600px !important;
}

.r-363 {
  background-position: -200px -4600px !important;
}

.r-362 {
  background-position: -240px -4600px !important;
}

.r-361 {
  background-position: -280px -4600px !important;
}

.r-360 {
  background-position: -320px -4600px !important;
}

.r-359 {
  background-position: -360px -4600px !important;
}

.r-358 {
  background-position: -400px -4600px !important;
}

.r-357 {
  background-position: -440px -4600px !important;
}

.r-356 {
  background-position: -480px -4600px !important;
}

.r-355 {
  background-position: -520px -4600px !important;
}

.r-354 {
  background-position: -560px -4600px !important;
}

.r-353 {
  background-position: -600px -4600px !important;
}

.r-352 {
  background-position: -640px -4600px !important;
}

.r-351 {
  background-position: -680px -4600px !important;
}

.r-342 {
  background-position: -720px -4600px !important;
}

.r-341 {
  background-position: -760px -4600px !important;
}

.r-340 {
  background-position: -800px -4600px !important;
}

.r-339 {
  background-position: -840px -4600px !important;
}

.r-338 {
  background-position: -880px -4600px !important;
}

.r-337 {
  background-position: -920px -4600px !important;
}

.r-336 {
  background-position: -960px -4600px !important;
}

.r-335 {
  background-position: 0 -4640px !important;
}

.r-334 {
  background-position: -40px -4640px !important;
}

.r-328 {
  background-position: -80px -4640px !important;
}

.r-327 {
  background-position: -120px -4640px !important;
}

.r-326 {
  background-position: -160px -4640px !important;
}

.r-325 {
  background-position: -200px -4640px !important;
}

.r-324 {
  background-position: -240px -4640px !important;
}

.r-323 {
  background-position: -280px -4640px !important;
}

.r-322 {
  background-position: -320px -4640px !important;
}

.r-321 {
  background-position: -360px -4640px !important;
}

.r-318 {
  background-position: -400px -4640px !important;
}

.r-317 {
  background-position: -440px -4640px !important;
}

.r-316 {
  background-position: -480px -4640px !important;
}

.r-315 {
  background-position: -520px -4640px !important;
}

.r-314 {
  background-position: -560px -4640px !important;
}

.r-311 {
  background-position: -600px -4640px !important;
}

.r-309 {
  background-position: -640px -4640px !important;
}

.r-308 {
  background-position: -680px -4640px !important;
}

.r-307 {
  background-position: -720px -4640px !important;
}

.r-306 {
  background-position: -760px -4640px !important;
}

.r-305 {
  background-position: -800px -4640px !important;
}

.r-304 {
  background-position: -840px -4640px !important;
}

.r-302 {
  background-position: -880px -4640px !important;
}

.r-301 {
  background-position: -920px -4640px !important;
}

.r-300 {
  background-position: -960px -4640px !important;
}

.r-299 {
  background-position: 0 -4680px !important;
}

.r-298 {
  background-position: -40px -4680px !important;
}

.r-297 {
  background-position: -80px -4680px !important;
}

.r-296 {
  background-position: -120px -4680px !important;
}

.r-295 {
  background-position: -160px -4680px !important;
}

.r-294 {
  background-position: -200px -4680px !important;
}

.r-293 {
  background-position: -240px -4680px !important;
}

.r-292 {
  background-position: -280px -4680px !important;
}

.r-291 {
  background-position: -320px -4680px !important;
}

.r-290 {
  background-position: -360px -4680px !important;
}

.r-289 {
  background-position: -400px -4680px !important;
}

.r-288 {
  background-position: -440px -4680px !important;
}

.r-287 {
  background-position: -480px -4680px !important;
}

.r-286 {
  background-position: -520px -4680px !important;
}

.r-285 {
  background-position: -560px -4680px !important;
}

.r-284 {
  background-position: -600px -4680px !important;
}

.r-283 {
  background-position: -640px -4680px !important;
}

.r-282 {
  background-position: -680px -4680px !important;
}

.r-281 {
  background-position: -720px -4680px !important;
}

.r-280 {
  background-position: -760px -4680px !important;
}

.r-279 {
  background-position: -800px -4680px !important;
}

.r-278 {
  background-position: -840px -4680px !important;
}

.r-276 {
  background-position: -880px -4680px !important;
}

.r-275 {
  background-position: -920px -4680px !important;
}

.r-274 {
  background-position: -960px -4680px !important;
}

.r-273 {
  background-position: 0 -4720px !important;
}

.r-272 {
  background-position: -40px -4720px !important;
}

.r-271 {
  background-position: -80px -4720px !important;
}

.r-270 {
  background-position: -120px -4720px !important;
}

.r-268 {
  background-position: -160px -4720px !important;
}

.r-267 {
  background-position: -200px -4720px !important;
}

.r-266 {
  background-position: -240px -4720px !important;
}

.r-265 {
  background-position: -280px -4720px !important;
}

.r-264 {
  background-position: -320px -4720px !important;
}

.r-257 {
  background-position: -360px -4720px !important;
}

.r-256 {
  background-position: -400px -4720px !important;
}

.r-255 {
  background-position: -440px -4720px !important;
}

.r-254 {
  background-position: -480px -4720px !important;
}

.r-253 {
  background-position: -520px -4720px !important;
}

.r-252 {
  background-position: -560px -4720px !important;
}

.r-251 {
  background-position: -600px -4720px !important;
}

.r-250 {
  background-position: -640px -4720px !important;
}

.r-249 {
  background-position: -680px -4720px !important;
}

.r-244 {
  background-position: -720px -4720px !important;
}

.r-243 {
  background-position: -760px -4720px !important;
}

.r-242 {
  background-position: -800px -4720px !important;
}

.r-241 {
  background-position: -840px -4720px !important;
}

.r-240 {
  background-position: -880px -4720px !important;
}

.r-239 {
  background-position: -920px -4720px !important;
}

.r-238 {
  background-position: -960px -4720px !important;
}

.r-237 {
  background-position: 0 -4760px !important;
}

.r-236 {
  background-position: -40px -4760px !important;
}

.r-235 {
  background-position: -80px -4760px !important;
}

.r-234 {
  background-position: -120px -4760px !important;
}

.r-233 {
  background-position: -160px -4760px !important;
}

.r-232 {
  background-position: -200px -4760px !important;
}

.r-231 {
  background-position: -240px -4760px !important;
}

.r-230 {
  background-position: -280px -4760px !important;
}

.r-229 {
  background-position: -320px -4760px !important;
}

.r-228 {
  background-position: -360px -4760px !important;
}

.r-227 {
  background-position: -400px -4760px !important;
}

.r-226 {
  background-position: -440px -4760px !important;
}

.r-225 {
  background-position: -480px -4760px !important;
}

.r-224 {
  background-position: -520px -4760px !important;
}

.r-223 {
  background-position: -560px -4760px !important;
}

.r-222 {
  background-position: -600px -4760px !important;
}

.r-221 {
  background-position: -640px -4760px !important;
}

.r-220 {
  background-position: -680px -4760px !important;
}

.r-219 {
  background-position: -720px -4760px !important;
}

.r-204 {
  background-position: -760px -4760px !important;
}

.r-203 {
  background-position: -800px -4760px !important;
}

.r-202 {
  background-position: -840px -4760px !important;
}

.r-201 {
  background-position: -880px -4760px !important;
}

.r-200 {
  background-position: -920px -4760px !important;
}

.r-199 {
  background-position: -960px -4760px !important;
}

.r-198 {
  background-position: 0 -4800px !important;
}

.r-185 {
  background-position: -40px -4800px !important;
}

.r-184 {
  background-position: -80px -4800px !important;
}

.r-183 {
  background-position: -120px -4800px !important;
}

.r-182 {
  background-position: -160px -4800px !important;
}

.r-181 {
  background-position: -200px -4800px !important;
}

.r-180 {
  background-position: -240px -4800px !important;
}

.r-179 {
  background-position: -280px -4800px !important;
}

.r-178 {
  background-position: -320px -4800px !important;
}

.r-177 {
  background-position: -360px -4800px !important;
}

.r-176 {
  background-position: -400px -4800px !important;
}

.r-164 {
  background-position: -440px -4800px !important;
}

.r-163 {
  background-position: -480px -4800px !important;
}

.r-162 {
  background-position: -520px -4800px !important;
}

.r-161 {
  background-position: -560px -4800px !important;
}

.r-160 {
  background-position: -600px -4800px !important;
}

.r-159 {
  background-position: -640px -4800px !important;
}

.r-158 {
  background-position: -680px -4800px !important;
}

.r-157 {
  background-position: -720px -4800px !important;
}

.r-156 {
  background-position: -760px -4800px !important;
}

.r-155 {
  background-position: -800px -4800px !important;
}

.r-154 {
  background-position: -840px -4800px !important;
}

.r-153 {
  background-position: -880px -4800px !important;
}

.r-152 {
  background-position: -920px -4800px !important;
}

.r-139 {
  background-position: -960px -4800px !important;
}

.r-138 {
  background-position: 0 -4840px !important;
}

.r-137 {
  background-position: -40px -4840px !important;
}

.r-136 {
  background-position: -80px -4840px !important;
}

.r-135 {
  background-position: -120px -4840px !important;
}

.r-134 {
  background-position: -160px -4840px !important;
}

.r-133 {
  background-position: -200px -4840px !important;
}

.r-132 {
  background-position: -240px -4840px !important;
}

.r-131 {
  background-position: -280px -4840px !important;
}

.r-116 {
  background-position: -320px -4840px !important;
}

.r-115 {
  background-position: -360px -4840px !important;
}

.r-114 {
  background-position: -400px -4840px !important;
}

.r-113 {
  background-position: -440px -4840px !important;
}

.r-112 {
  background-position: -480px -4840px !important;
}

.r-111 {
  background-position: -520px -4840px !important;
}

.r-110 {
  background-position: -560px -4840px !important;
}

.r-109 {
  background-position: -600px -4840px !important;
}

.r-102 {
  background-position: -640px -4840px !important;
}

.r-101 {
  background-position: -680px -4840px !important;
}

.r-100 {
  background-position: -720px -4840px !important;
}

.r-99 {
  background-position: -760px -4840px !important;
}

.r-98 {
  background-position: -800px -4840px !important;
}

.r-97 {
  background-position: -840px -4840px !important;
}

.r-96 {
  background-position: -880px -4840px !important;
}

.r-95 {
  background-position: -920px -4840px !important;
}

.r-94 {
  background-position: -960px -4840px !important;
}

.r-90 {
  background-position: 0 -4880px !important;
}

.r-89 {
  background-position: -40px -4880px !important;
}

.r-88 {
  background-position: -80px -4880px !important;
}

.r-87 {
  background-position: -120px -4880px !important;
}

.r-86 {
  background-position: -160px -4880px !important;
}

.r-85 {
  background-position: -200px -4880px !important;
}

.r-84 {
  background-position: -240px -4880px !important;
}

.r-76 {
  background-position: -280px -4880px !important;
}

.r-75 {
  background-position: -320px -4880px !important;
}

.r-74 {
  background-position: -360px -4880px !important;
}

.r-73 {
  background-position: -400px -4880px !important;
}

.r-72 {
  background-position: -440px -4880px !important;
}

.r-71 {
  background-position: -480px -4880px !important;
}

.r-68 {
  background-position: -520px -4880px !important;
}

.r-67 {
  background-position: -560px -4880px !important;
}

.r-66 {
  background-position: -600px -4880px !important;
}

.r-65 {
  background-position: -640px -4880px !important;
}

.r-64 {
  background-position: -680px -4880px !important;
}

.r-63 {
  background-position: -720px -4880px !important;
}

.r-62 {
  background-position: -760px -4880px !important;
}

.r-61 {
  background-position: -800px -4880px !important;
}

.r-60 {
  background-position: -840px -4880px !important;
}

.r-59 {
  background-position: -880px -4880px !important;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.left-4 {
  left: 1rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-2 {
  height: .5rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-full {
  height: 100%;
}

.w-10 {
  width: 2.5rem;
}

.w-2 {
  width: .5rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.max-w-2xl {
  max-width: 42rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.overflow-hidden {
  overflow: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border-0 {
  border-width: 0;
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-4 {
  padding: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-4 {
  padding-right: 1rem;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.r-fix-scrollbar {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}

.hover\:bg-emerald-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.hover\:text-emerald-500:hover {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.focus\:bg-emerald-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (min-width: 640px) {
  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

/*# sourceMappingURL=index.d2ac6405.css.map */
